import Vue from 'vue'
import store from '@/store/index' //para acceder a state para isLogged
import {  consoleMsgFinally, makeArrayCategories, sortalphabeticallyalter } from '@/helpers/helpers'

export async function fetchHotels( data) {
    const { onlyDingusCode, all , showInWeb } = data
    try {
        const response = await Vue.axios({
            url: '/catalogs/hotels/'
        })
        const payload = response && response.data
        if( onlyDingusCode ){
            const hotels = payload.filter( hotel => hotel.dingusCode != null )
            return hotels
        }

        if( all ){
           return payload
        }

        if( showInWeb ){
            const hotels = payload.filter( hotel => hotel.showinweb )
            return hotels
        }
    } catch (error) {
        console.log(error)
        return []
    } finally {
        consoleMsgFinally('start/fetchHotels', 'end')
    }
}
export async function fetchHotel( ) {    
  
    try {
        const response = await Vue.axios({
            url: '/catalogs/hotels/'
        })
        const payload = response && response.data 
        
            const hotels = payload.filter( hotel => hotel.dingusCode != null )
            return hotels
       
        
   
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchHotels', 'end')
    }
}
export async function fetchCurrencies() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/currency/'
        })
        const payload = response && response.data         
        return payload.filter(currency => ["USD", "MXN"].includes(currency.code))

    } catch (error) {
        console.log(error)
        return []
    } finally {
        consoleMsgFinally('start/fetchCurrencies', 'end')
    }
}

export async function fetchCategories() {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {
        
        const response = await Vue.axios({
            url: '/catalogs/category/',
            params: {idseccanal
             }
        })
        const payload = response && response.data
     
        const categories = makeArrayCategories( payload )

        return categories

    } catch (error) {
        console.log(error)
        return []
    } finally {
        consoleMsgFinally('start/fetchCategories', 'end')
    }
}

export async function fetchDestinations() {

    try {
        const response = await Vue.axios({
            url: '/catalogs/destinos/'
        })
        const payload = response && response.data

        payload.forEach((destination) => {
            destination.value = destination.name
            destination.text = destination.name
            delete destination.id
            delete destination.name
        })
        return payload
    } catch (error) {
        console.log( error )
    } finally {
        consoleMsgFinally('start/fetchDestinations', 'end')
    }
}

export async function getHotels(context) {
    try {
        const response = await Vue.axios({
            url: '/catalogs/hotels/'
        })
        const payload = response && response.data
        return payload || []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        consoleMsgFinally('start/fetchHotels', 'end')
    }
}

export async function fetchDepartaments() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/departamentos/'
        })
        const payload = response && response.data
        //ordeno alfabeticamente los nombres de los departamentos
        payload.sort((a, b) => a.name.localeCompare(b.name))
        payload.forEach((departament) => {
            departament.value = departament.id,
            departament.text = departament.name
            delete departament.id
            delete departament.deleted
            delete departament.name
            delete departament.createdate
        })
        return payload
    } catch (error) {
        console.log( error )
    } finally {
        consoleMsgFinally('start/fetchDepartaments', 'end')
    }
}

export async function fetchAirlines() {

    try {
        const response = await Vue.axios({
            url: '/catalogs/aerolineas/'
        })
        const payload = response && response.data
        return sortalphabeticallyalter(payload)
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchAirlines', 'end')

    }
}

export async function fetchTypeClients() {

    try {
        const response = await Vue.axios({
            url: '/catalogs/tipocliente/'
        })
        const payload = response && response.data
        payload.sort(function(a, b){
            if(a.name < b.name) { return -1}
            if(a.name > b.name) { return 1}
            return 0
        })
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchTypeClients', 'end')
    }
}

//Obtener terminos y condiciones por canal
export async function fetchTerms(context, datos) {
    const { typeTerms, idLanguage} = datos
    console.log( datos )
    try {
        const response = await Vue.axios({
            url: '/catalogs/terminosycondiciones/',
            params: {
                typeTerms,
                idLanguage
            }
        })
        const payload = response && response.data        
        return payload
    } catch (error) {
        console.log( error)
    } finally {
        consoleMsgFinally('start/fetchTerms', 'end')
    }
}

export async function fetchPromoCodes() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/promoCodes/'
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchPromoCodes', 'end')
    }
}

export async function fetchLanguages() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/language/'
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
        return []
    } finally {
        consoleMsgFinally('start/fetchLanguages', 'end')
    }
}

export async function fetchStatus() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/status/'
        })
        const payload = response && response.data      
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchStatus', 'end')
    }
}

export async function fetchPaymentMethods() {
    try {
        const response = await Vue.axios({
            url: `/catalogs/paymentmethod/`,
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchPaymentMethods end')
    }
}

export async function fetchTypeService() {
    try {
        const response = await Vue.axios({
            url: `/catalogs/itineraryServiceTypes/`,
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchTypeService end')
    }
}

export async function fetchItineraryRules() {
    try {
        const response = await Vue.axios({
            url: `/itineraryrules/`,
        })
        const {data} = response && response.data
        return data
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchItineraryRules end')
    }
}

export async function getGenericParameter(context, id) {
    try {
        const response = await Vue.axios({
            url: `/genericparameters/`,
            params: {
                idParameter: id
            }
        })

        const respuesta = response && response.data
        return respuesta?.status ? respuesta?.data?.value : false
    } catch (error) {
        return false
    } finally {
        consoleMsgFinally('getGenericParameters end')
    }
}