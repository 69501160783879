
<template>
  <b-card>
    <Filters
      :isLoadingProfit="isLoadingProfit"
      :isDowloadingExcel="isDowloadingExcel"
      @look-per-filter="filteredSearch"
    />
   <br>
    <TableCosts
      v-if="!isLoadingProfit "
      :profitData="profitDataList"
      :tableColumns="tableColumns"
      :sortBy="sortBy"
      :isSortDirDesc="isSortDirDesc"
      :isEmpty="isEmpty"
      :isLoadingExcel="isDowloadingExcel"
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :errorsMsgMonth="errorsMsgMonth"
      
      fixed
      responsive
      small
      style="overflow:auto"
      @save-profit="saveProfit"
      @calculate-profit="calculateProfit"
      @recalculate-profit="reCalculateProfit"
      @change-per-page="changePerPage"
      @set-tab-index="setTabIndex"
      @export-profit="downloadExcel"
    />

     <div class="isCentered isSpinner" v-else>
      <center>
        <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
        Cargando distribución de rendimiento
      </center>
    </div>

    <PaginationTable
      :currentPage="currentPage"
      :totalRows="filteredDetailProfit.length"
      :perPage="perPage"
      @change-page="changePage"
      v-if="filteredDetailProfit.length > 10 && !isLoadingProfit"
    />
  </b-card>
</template>

<script>
import { formatDateOnly, toDecimal } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Filters from '@/modules/fivesClub/components/profit/costs/Filters'
import TableCosts from '@/modules/fivesClub/components/profit/costs/TableCosts'
import PaginationTable from '@/modules/fivesClub/components/profit/costs/PaginationTable'
import { monthList } from "@/data/data"

export default {
  components:{
    TableCosts,
    PaginationTable,
    Filters,
  },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      perPageOptions: [50, 100, 150, 200, 250, 500, 1000],
      sortBy: 'name',
      isSortDirDesc: true,
      refListTable : null,
      statusFilter: null,
      isDowloadingExcel:false,
      tableColumns: [
        { key: "contractcode", label: "Contrato", class: "text-center" },
        { key: "ownercode", label: "Propietario", class: "text-center" },
        { key: "ownername", stickyColumn: true, label: "Nombre", class: "text-center" },
        { key: "housingnumber", label: "Número de vivienda", class: "text-center" },
        { key: "vigency", label: "Vigencia", class: "text-center" },
        { key: "housingpoints", label: "Puntos", class: "text-center" },
        { key: "shareBar", label: "% Share BAR", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "operationNights", label: "Noches operación	", class: "text-center" },
        { key: "usedNights", label: "Noches ocupadas", class: "text-center" },
        { key: "netIncome", label: "Ingreso Neto", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "goVariables", label: "G.O. Variables", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "hiltonFee", label: "Cuota Hilton", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "hiltonmarketingFee", label: "Cuota Hilton Marketing", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "franchiseFee", label: "Cuota Franquicia", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "operatorbaseFee", label: "Cuota base de operador", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "incentiveFee", label: "Cuota incentivo", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "admonFee", label: "Cuota administrativa", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "reserveFund", label: "Fondo de Reserva", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "tmBenefit", label: "Propietaria o TM", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "ownerBenefit", label: "Beneficio propietario", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "previousBenefit", label: "Beneficio anterior", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "maintenanceFee", label: "Cuota Mant.", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "remainingBalance", label: "Saldo resultante", class: "text-center", formatter: value => { return this.formatDecimal(value) } },
        { key: "operatorProfit", label: "Beneficio operadora", class: "text-center", formatter: value => { return this.formatDecimal(value) } },

      ],
      isLoadingProfit: false,
      isEmpty: false,
      errorsMsgMonth: '',
    }
  },
  async mounted() {
    this.isEmpty=true
    this.setProfit([])
  },
  computed: {
    ...mapState("fivesClubProfit", ["blockingDataProfit", "rentalPoolFilter"]),
    ...mapGetters('fivesClubProfit',['filteredDetailProfit']),
    profitDataList(){
      if (this.filteredDetailProfit?.length) return this.filteredDetailProfit?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
      else return []
    },
  },
  methods: {
  ...mapMutations('fivesClubProfit', ['setProfit','setBlockingDataProfit']),
  ...mapActions('fivesClubProfit', ['fetchProfitGeneral','saveProfitClose','calculateProfitCost','reCalculateProfitCost','fetchExcelProfitCost']),
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    },
    formatDecimal(number) {
      return toDecimal(number);
    },
    async filteredSearch(payload){
      this.isLoadingProfit = true
      this.errorsMsgMonth = ''
      const profitData = await this.fetchProfitGeneral( payload )
      this.setBlockingDataProfit(payload)
      this.setProfit(profitData)
      this.isEmpty=false
      this.isLoadingProfit = false
    },
    async saveProfit(){
      this.errorsMsgMonth = ''
      const payload = this.blockingDataProfit
      const result = await this.saveProfitClose(payload)
      this.errorsMsgMonth = result.message || ''
      if (result.status) await this.filteredSearch(payload)
      this.isLoadingProfit = false
    },
    async calculateProfit(){
      this.errorsMsgMonth = ''
      this.isLoadingProfit = true
      const payload = this.blockingDataProfit
      const result= await this.calculateProfitCost(payload)
      if (result.status) await this.filteredSearch(payload)
      this.isLoadingProfit = false
    },
    async reCalculateProfit(){
      this.errorsMsgMonth = ''
      this.isLoadingProfit = true
      const payload = this.blockingDataProfit
      const result = await this.reCalculateProfitCost(payload)
      if (result.status) await this.filteredSearch(payload)
      this.isLoadingProfit = false
    },

     async downloadExcel() {
      this.isDowloadingExcel=true
      const {month, rentalPoolId} = this.blockingDataProfit
      const rentalPool = this.rentalPoolFilter?.find(pools => pools.id == rentalPoolId)?.name || ''
      const monthName = monthList?.find(monthlist => monthlist.value == month)?.text || ''

      const filename = ` ${rentalPool} ${monthName} ${this.blockingDataProfit.year}`

      const payload = {...this.blockingDataProfit, downExcel: true, filename }
      await this.fetchExcelProfitCost(payload)
      this.isDowloadingExcel=false
    },
  },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>
