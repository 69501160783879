<template>
	<div class="mywrapper">
		<div class="container-thanks">

			<header class="hero">
				<img src="https://backsales.thefives.com.mx/media/logo-tfhr-negro.jpg" alt="logo tfb" class="hero__img">
			</header>

			<main>
				<div class="thank-you-card" v-if="validData">
					<b-overlay :show="isLoadingAuthInfo" rounded class="p-2">
						<p class="message">
							Estimado <span class="font-weight-bold">{{ user }}:</span><br><br>
							<span :class="customStatus" v-html="message"></span>
							<span v-if="status && attended" class="mt-1">
								<br>Resolución:
								<br><strong :class="statusData ? 'accepted' : 'rejected'">Se {{ statusData ? 'autorizó' : 'declinó' }} la solicitud</strong>
							</span>
						</p> <br>
						<p>Valoramos el tiempo y la consideración que ha dedicado para evaluar la solicitud.</p>
					</b-overlay>
				</div>

				<div class="thank-you-card" v-else>
					<h2 class="mb-1">
						Pagina no encontrada
					</h2>



					<!-- image -->
						<b-img
						class="hero__img"
						:src="imgUrl"
						alt="Error page"
					/>

					<b-button
						variant="primary"
						class="mb-2 btn-sm-block"
						@click="redirectToDefault"
						>
						Regresar a Inicio
					</b-button>

				</div>
			</main>

		</div>

		<footer class="footer">
			<p class="copyright">&copy; All Rights Reserved © {{new Date().getFullYear()}} <br/>Acceso Xcalacoco S/N. Fracc. El Limonar 1, Playa Del Carmen, Quintana Roo, C.P. 77710</p>
		</footer>

	</div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { login } from '@/modules/auth/mixins/login'

export default {
	mixins: [login],
	data() {
		return {
			downImg: require('@/assets/images/pages/error.svg'),
			statusData: null,
			user: null,
			message: null,
			validData: false,
			unattended: false,
			isLoadingAuthInfo: false,
			status: false,
			attended: false
		}
	},
	async created() {
		await this.init()
	},
	computed: {
		...mapState("appConfig", ["layout"]),
		imgUrl() {
			if (this.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.downImg = require('@/assets/images/pages/error-dark.svg')
				return this.downImg
			}
			return this.downImg
		},
		customStatus(){
			if (this.status) return this.attended ? 'info' : this.statusData ? 'accepted' : 'rejected'
			else return 'error'
		}
	},
	methods: {
		...mapActions('auth', ['fetchCatalogAuthorization']),
		async init(){
			const { userId, idAuthorizations, statusAuthorization } = this.$route.query
			this.validData = !!userId && !!idAuthorizations && !!statusAuthorization
			if( this.validData ){
				this.isLoadingAuthInfo = true
				const response = await this.fetchCatalogAuthorization({userId, idAuthorizations, statusAuthorization})
				if (response) {
					console.log(response)
					this.user = response?.nameUser || 'Usuario'
					this.statusData = (typeof response?.statusData == 'string') ? Boolean(parseInt(response?.statusData)) : response?.statusData
					this.message = response?.message
					this.status = response?.status
					this.attended = response?.attended
				}
				this.isLoadingAuthInfo = false
			}
		},
		isANumber(string){
			return string ? !isNaN( string ) && string > 0 : false
		},
	}

}
</script>

<style>

.mywrapper {
	height: 100vh;
	margin: 0;
	padding: 0;
	background-color: #ffffff;
}

.container-thanks{
	min-height: 100%;
	position: relative;
	padding-bottom: 100px; /* Altura del footer */
}
/* Estilos del hero */


.hero__img{
	width: 100%;
	max-width: 320px;
	height: 200px;
	margin: 0 auto 3rem; /*centrar img en su contenedor*/
	display: block; /*la imagen dejan un margen abajo, con el display block elimino ese margen*/
}


main {
	margin: 0 auto; /*cetrno este main*/
	/* border: 1px solid #000; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.thank-you-card {
	/* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
	text-align: center;
	background-color: #F2F2F2;
	padding: 20px;
	width: 100%;
	min-width: 250px; /* Ancho mínimo de la tarjeta */
	max-width: 450px; /* Ancho máximo de la tarjeta */
	margin: 0 auto; /* Centrar la tarjeta en la página */
}

/* Media queries para ajustar el tamaño de la tarjeta en diferentes pantallas */
@media (min-width: 768px) {
.thank-you-card  {
	min-width: 350px; /* Ancho mínimo de la tarjeta para pantallas más grandes */
	max-width: 700px; /* Ancho máximo de la tarjeta para pantallas más grandes */
}
}

@media (min-width: 1024px) {
.thank-you-card  {
	min-width: 400px; /* Ancho mínimo de la tarjeta para pantallas aún más grandes */
	max-width: 750px; /* Ancho máximo de la tarjeta para pantallas aún más grandes */
}
}

h1 {
	color: #344054;
}
p {
	color: #0a0a0a;
	font-size: 1.2rem;

}
.accepted, .accepted p {
	color: #43a047;
}
.rejected, .rejected p {
	color: #ea5455;
}
.error, .error p {
	color: #ff7300;
}
.info, .info p {
	color: #00bfff;
}
.message {
	margin-top: 20px;
}
.signature {
	margin-top: 40px;
	color: #344054;
}

/* Estilos del footer */
.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 100px; /* Altura del footer */

	background-color: #333;
	color: #fff;
	padding: 20px;
	text-align: center;
}


.copyright {
	color: white;
	margin-top: 1rem;
}
/* Estilos generales */
@media (min-width: 768px) {
/* Aplicar estilos para pantallas más grandes */
main {
	max-width: 1440px;
}
}

</style>