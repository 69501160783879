<template>
  <b-modal
    size="lg"
    :id="'modal-reference' + roomInfoData.id"
    :title="groupReservationTitle"
    ok-only
    hide-footer
    class="status"
    :no-enforce-focus='true'
  >
    <b-card-text>

      <ValidationObserver ref="ModalEditBookingGroupForm">
        <h3>Información de la Reservación</h3>

        <b-row>
          <b-col md="2">
            <ValidationProvider name="Prefijo" rules="required">
              <b-form-group label="Prefijo" slot-scope="{ valid, errors }">
                <b-form-select
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="room.nameprefix"
                  :disabled="invalid"
                >
                  <option value="0">Seleccione una opción</option>
                  <option
                    v-for="(namesF, index) in namesPrefix"
                    :key="index"
                    :value="namesF.value"
                  >
                    {{ namesF.text }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider rules="required" name="Nombre">
              <b-form-group slot-scope="{ valid, errors }" label="Nombre">
                <b-form-input
                  type="text"
                  :disabled="invalid"
                  v-model="room.givenname"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Apellido">
              <b-form-group slot-scope="{ valid, errors }" label="Apellido">
                <b-form-input
                  type="text"
                  :disabled="invalid"
                  v-model="room.surname"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Correo">
              <b-form-group slot-scope="{ valid, errors }" label="Correo">
                <b-form-input
                  type="text"
                  :disabled="invalid"
                  v-model="room.email"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider rules="required" name="teléfono">
              <b-form-group slot-scope="{ valid, errors }" label="Teléfono">
                <b-form-input
                  type="text"
                  :disabled="invalid"
                  @keypress="formatPhoneNumber"
                  v-model="room.phonenumber"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label><strong>Check in - Check out*</strong></label>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="configRangeDate"
                @input="setRangeDaysAndTotalNights(rangeDate)"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <ValidationProvider name="Noches">
              <b-form-group slot-scope="{ isValidNights, errors }" label="Noches">
                <b-form-input
                  type="number"
                  :disabled="true"
                  v-model="room.nights"
                  :state="errors[0] ? false : isValidNights ? true : null"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
            <label v-if="isValidNights" class="nightsValid">Noches invalidas </label>
          </b-col>

          <b-col md="3">
            <ValidationProvider name="Tarifa">
              <b-form-group slot-scope="{ isValidNights }" label="Tarifa">
                <b-form-input
                  type="number"
                  :disabled="true"
                  v-model="room.rateInit"
                  @input="getNights(room)"
                  :state="isValidNights"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider name="Total">
              <b-form-group label="Total">
                <b-form-input
                  type="number"
                  :disabled="true"
                  v-model="room.total"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12" v-if="availability">
            <b-alert variant="danger" show>
              <div class="alert-body text-center">
                <span>
                  <strong>¡Sin Tarifas! </strong>No se encontro
                  disponibilidad.
                </span>
              </div>
            </b-alert>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Tipo de Cuarto">
              <b-form-group
                :disabled="invalid"
                slot-scope="{ valid, errors }"
                label="Tipo de Cuarto"
              >
                <b-form-select
                  v-model="room.roomtypecode"
                  :state="errors[0] ? false : valid ? true : null"
                  @change="validMaxPax(room.roomtypecode)"
                >
                  <option
                    v-for="roomsItems in room.roomslist"
                    :key="roomsItems.room_id"
                    :value="roomsItems.roomtypecode"
                  >
                    {{ roomsItems.roomtypename }} | {{ roomsItems.rooms }}
                  </option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="2">
            <b-form-group>
              <validation-provider name="Adultos">
                <b-form-group slot-scope="{ errors }" label="Adultos*">
                  <b-form-select
                    :disabled="invalid"
                    v-model="room.adults"
                    @input="setCanEdit()"
                    :state="errors.length > 0 ? false : null"
                  >
                    <!-- @input="setDataForm" -->
                    <option
                      v-for="(adulto, index) in validPax.adults "
                      :key="index"
                      :value="adulto"
                    >
                      {{ adulto }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2" v-if="validPax.canBookChildren">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Niños"
              >
                <label for="children">Niños*</label>
                <b-form-select
                  :disabled="invalid"
                  v-model="room.children"
                  :state="errors.length > 0 ? false : null"
                  @input="createArrayNinios(room), setCanEdit()"
                >
                  <option value="0">0</option>
                  <option
                    v-for="(children, index) in validPax.children "
                    :key="index"
                    :value="children"
                  >
                    {{ children }}
                  </option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2" class="buttonUpdate">
            <b-button
              class="mt-2"
              style="font-size:13px;"
              :disabled="(isValidNights || ismaxPax) || isRoomFull || isSaving || isUpdating"
              variant="primary"
              @click="updateRates(room)"
            >
              Actualizar <b-spinner small v-if="isUpdating" />
            </b-button>
          </b-col>

          <b-col md="12" v-if="ismaxPax">
            <label class="nightsValid">Numero de pax maximo N° {{ validPax.pax }} </label>
          </b-col>

          <b-col md="4 mb-1" v-for="(child, index) in room.booking.ninios " :key="index">
            <label :for="'AgeChild' + index">Edad niño {{ index + 1 }}*</label>
            <b-input-group>
              <b-form-select
                :disabled="invalid"
                :id="'AgeChild' + index"
                v-model="room.booking.ninios[index].edad"
                @change="setAgeNinio(index, room.booking.ninios[index].edad, room), setCanEdit()"
              >
                <option
                  v-for="(edad, index) in ageChildrenOptions"
                  :key="index"
                  :value="edad.value"
                >
                  {{ edad.text }}
                </option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteAgeChild(index, room)"
                >
                  <b-icon icon="trash-fill"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="12" v-if="show == true">
            <h3 class="mb-2">Pagos Parciales</h3>
          </b-col>
          <b-col md="3" v-if="show == true">
            <b-form-group label="Noches">
              <b-form-select
                :disabled="invalid"
                v-model="room.orderdetail.nightspay"
                @change="setPriceNights(room)"
              >
                <option value="0">Noches</option>
                <option v-for="(night, index) in room.nights" :key="index" :value="night">
                  {{ night }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Referido">
              <b-form-select
                :disabled="invalid"
                v-model="room.reference"
              >
                <option :value="null">Seleccione Referido</option>
                <option
                  v-for="(reference, index) in contractInfo.referenceList "
                  :key="index"
                  :value="reference"
                >
                  {{ reference }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="show == true">
            <ValidationProvider rules="min:0" name="Primer Pago">
              <b-form-group slot-scope="{ valid, errors }" label="Primer Pago ">
                <b-form-input
                  type="number"
                  :disabled="invalid"
                  v-model="room.orderdetail.firstpay"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Pago parcial a pagar"
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6" v-if="room.listPayOrders.data.length != 0 &&  show == true">
            <table class="table animate__animated animate__bounce">
              <tbody>
                <tr>
                  <h4 class="listPayTitle">Detalle de pagos </h4>

                  <table class="listPay">
                    <tr>
                      <th>Fecha</th>
                      <th>Pago</th>

                      <th>Estado</th>
                      <th>Moneda</th>
                    </tr>
                    <tr v-for="listPay in room.listPayOrders.data" :key="listPay.id">
                      <td class="text-nowrap">
                        {{ listPay.date_trans ? listPay.date_trans : "" }}
                      </td>

                      <td class="text-nowrap">{{ formatCurrency(listPay.paid, listPay.code_currency) }}</td>

                      <td>{{ listPay.trans_status }}</td>
                      <td>{{ listPay.code_currency }}</td>
                    </tr>
                  </table>
                </tr>
              </tbody>
            </table>
            <div>
              <strong>Balance:</strong> {{ formatCurrency(balanceBooking) }}
            </div>
          </b-col>

          <b-col md="12" class="mt-2">
            <div class="float-right">
              <b-button
                :disabled="invalid || isEdit || isValidNights || isSaving "
                variant="primary"
                @click="updateRoom(room)"
              >
                {{isSaving ? "Guardando" : "Guardar"}} <b-spinner small v-if="isSaving" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </ValidationObserver>
    </b-card-text>
  </b-modal>
</template>

<script>
import * as moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import { currentDate, showAlertMessage, toCurrency } from "@/helpers/helpers"
import { adultOptions, childrenOptions, ageChildrenOptions, namesPrefix } from "@/data/data"
import { acl } from "@/modules/auth/mixins/acl"
import flatPickr from 'vue-flatpickr-component'
export default {
  mixins: [acl],
  props: {
    room: {
      type: Object,
      required: true,
    },
    roomCode: {
      type: String,
      required: true,
    },
    roomInfoData: {
      type: Object,
      required: true,
    },
    contractInfo: {
      type: Object,
      required: true,
    },
    roomsList: {
      type: Object,
      required: true,
    },
  },
  components: {
    flatPickr
  },
  data() {
    return {
      show: false,
      isSaving: false,
      fechaActual: currentDate(),
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
      namesPrefix,
      amount: 0,
      sum: 0,
      invalid: false,
      isValidNights: false,
      isEdit: false,
      isUpdating: false,
      rangeDate: null,
      setDatein: null,
      setDateout: null,
      nightsValid: this.room?.nights,
      availability: false,
      validPax: this.roomsList,
      isRoomFull: false,
      ismaxPax: false
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("groups", [
      "ninos",
      "numberChildren",
      "roomsTypes",

    ]),
    ...mapState("shop", ["cart", "errors", "paymentFormat"]),

    balanceBooking() {
      this.sum = this.room.listPayOrders.data.filter(payment => payment.trans_status == 'Approved').reduce((accum,item) => accum + item.amount_usd, 0)

      return ((Math.round((parseFloat(this.room.total) - parseFloat(this.sum)) * 100) / 100).toFixed(2))
    },
    bookingStatus() {
      let status = "Disponible"
      let headerBgVariant = 'disponible'

      if (this.balanceBooking > 0) {
        status = "Pendiente"
        headerBgVariant = 'pendiente'
      } else {
        status = "Pagado"
        headerBgVariant = 'pagado'
      }

      return {status, headerBgVariant}
    },
    groupReservationTitle(){
      return `${this.contractInfo?.name} | POS-${this.room?.orderdetail?.order_id}-${this.room?.orderdetail?.orderdetail_id} | ${this.bookingStatus?.status}`
    },
    configRangeDate() {
      this.validPax = this.room.roomslist.find(
        (item) => item.roomtypecode == this.room.roomtypecode
      );
      this.availability = false
      this.invalid = false;
      this.isValidNights = false;
      this.isEdit = false;
      let config
      let datein = moment(this.room.datein)
      let dateout = moment(this.room.dateout)
      this.setDatein = this.contractInfo.arrivaldate
      this.setDateout = this.contractInfo.departuredate
      let numNigths = parseFloat(this.contractInfo.additionalNights)

      this.rangeDate = `${this.room.datein} to ${this.room.dateout}`
      if (this.can('groups_menu_show_membership_flexible_dates')) {
        this.contractInfo.isFlexDate = this.can('groups_menu_show_membership_flexible_dates')
      }


      datein = moment(this.contractInfo.arrivaldate).subtract(numNigths, 'days').format('YYYY-MM-DD');
      dateout = moment(this.contractInfo.departuredate).add(numNigths, 'days').format('YYYY-MM-DD');

      config = { mode: 'range', minDate: datein, maxDate: dateout, static: true }

      return config
    },
  },
  methods: {
    ...mapActions("groups", ["fetchDataOrderBooking", "fetchTransactionsBooking"]),
    ...mapActions("groups", ["fetchUpdateRoomsDetail", "fetchRoomBooking", "fetchRatesGroup"]),
    ...mapActions("mySales", ["fetchTransactionPay"]),
    ...mapMutations("groups", [
      "addArrayNinios",
      "setAgeChild",
      "removeAgeChild",
      "setTotalNights",

    ]),
    ageChild(object) {
      return object.edad;
    },
    async getLinkPay(orderdetail) {
      const payload = {
        amount: orderdetail.totalpay,
        order: orderdetail.order_id,
        detail: orderdetail.orderdetail_id,
      };

      this.loadvalid = true
      await this.fetchTransactionPay(payload)
      this.loadvalid = false
      this.urlbank = this.transactionPay.link
    },
    createArrayNinios(numberOfChildren) {
      let ageChildrens = []
      for (let i = 0; i < numberOfChildren.children; i++) {
        ageChildrens.push({ edad: 1 })
      }
      const payload = { ageChildrens, info: numberOfChildren }
      this.addArrayNinios(payload)
    },
    setAgeNinio(indice, edad, room) {
      const payload = { indice, edad, room }
      this.setAgeChild(payload)
    },
    deleteAgeChild(indice, room) {
      const payload = { indice, info: room }

      this.removeAgeChild(payload)
    },
    getNights(room) {
      const datein = moment(this.contractInfo.arrivaldate)
      const dateout = moment(this.contractInfo.departuredate)
      this.room.nights = dateout.diff(datein, "days")
      this.room.total = room.rateInit * '0'
    },

    setRangeDaysAndTotalNights(rangeDateNew) {

      if (rangeDateNew.includes("to")) {
        const splitdate = rangeDateNew.split(' to ')

        const datein = moment(splitdate[0])
        const dateout = moment(splitdate[1])
        const dateinContract = moment(this.contractInfo.arrivaldate)
        const dateoutContract = moment(this.contractInfo.departuredate)

        this.room.total = this.room.rateInit * 0
        this.room.rateInit = 0
        this.nightsValid = dateout.diff(datein, "days")
        // const sameNight = this.calculateNights(dateout, dateinContract);

        // if (!this.contractInfo.isFlexDate) {
        //   this.isEdit = true
        //   this.isValidNights = false

        //   const validDates = this.areDatesAfterContractDates(datein, dateout, dateinContract, dateoutContract);
        //   this.isValidNights = !validDates
        // } else {
        //   if (this.contractInfo.nightsGroup>this.nightsValid ){
        //     this.isValidNights=true
        //   } else {
        //     this.isEdit=true
        //     this.isValidNights=false
        //     if ( sameNight!=0 && this.contractInfo.isFlexDate) {
        //       this.isValidNights=false
        //     } else {
        //       if (this.contractInfo.isFlexDate ) {
        //         this.isValidNights=true
        //       }
        //     }
        //   }
        // }

        this.room.nights = dateout.diff(datein, "days");
        this.setDatein = splitdate[0]
        this.setDateout = splitdate[1]

      }
    },

    setCanEdit() {
      const paxTotal = parseFloat(this.room.adults) + parseFloat(this.room.children)

      this.ismaxPax = paxTotal > this.validPax.pax

      this.setRangeDaysAndTotalNights(this.rangeDate)

    },

    validMaxPax(roomCode) {
      this.validPax = this.room.roomslist.find(
        (item) => item.roomtypecode == roomCode
      );


      this.room.adults = 1
      this.room.children = 0
      this.setRangeDaysAndTotalNights(this.rangeDate)
      this.setCanEdit()
      this.createArrayNinios(this.room)

      this.isRoomFull = this.validPax.rooms == 0
      this.isEdit = this.validPax.rooms == 0
    },

    areDatesAfterContractDates(datein, dateout, dateinContract, dateoutContract) {
      return datein <= dateinContract && dateout >= dateoutContract;
    },
    async updateRates(room) {

      const splitdate = this.rangeDate.split(' to ')
      this.setDatein = splitdate[0]
      this.setDateout = splitdate[1]
      this.isUpdating = true;
      const ages = room.booking.ninios.map(item => {
        return item.edad
      })

      const roomParameters = {
        "hotel": room.hotel,
        "groupId": room.group,
        "roomtypecode": room.roomtypecode,
        "dateIn": this.setDatein,
        "dateOut": this.setDateout,
        "adults": room.adults,
        "nights": room.nights,
        "ninos": ages,
        "isAdmin": true
      }

      const rates = await this.fetchRatesGroup(roomParameters);

      this.isUpdating = false;
      if (rates.total == 0) {
        this.availability = true
        return false
      } else {
        this.availability = false
      }
      this.room.rateInit = rates.rateavg
      this.room.total = rates.total
      this.isUpdating = false;
      this.isEdit = false
    },

    async setPriceNights(room) {
      this.setTotalNights(room);
    },
    async updateRoom(room) {
      room.datein = this.setDatein
      room.dateout = this.setDateout
      this.isSaving = true;
      let passRoom = room.room
      room.roomCategory = this.roomsTypes.find(
        (item) => item.code == room.roomtypecode
      );
      if (room.roomCategory) {
        room.roomtypename = room.roomCategory.name
        room.room = room.roomCategory.id
      }

      room.bookingid = room.id
      room.dateIn = room.datein
      room.dateOut = room.dateout
      room.vendor = room.vendorcode

      room.groupId = room.group
      room.roomId = room.room
      room.nightspay = room.orderdetail.nightspay
      room.partial = false
      room.paymentParcial = room.orderdetail.firstpay

      const ages = room.booking.ninios.map(item => {
        return item.edad
      })

      room.ninos = ages

      const getRoom = await this.fetchUpdateRoomsDetail(room);

      this.isSaving = false;
      this.$root.$emit("bv::hide::modal", "modal-reference" + room.id);

      const payload = {
        idGroup: room.group,
        idRoomOnly: "",
        idRoom: room.room,
        datein: room.datein,
        dateout: room.dateout,
      };
      if(room.room!=passRoom){
        if(getRoom==false){
        this.availability=true
        return showAlertMessage("No se ha modificado la reserva", "BellIcon", "No se ha podido guardar el registro, por favor inténtelo más tarde o contacte a soporte.", "warning", 4000)
      }else{
        this.availability=false
      }
         this.$emit('refresh')
      }
      await this.fetchRoomBooking(payload);

    },
    formatPhoneNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const respuesta = codesAllowed.includes(keyCode);
      if (!respuesta) event.preventDefault()
    },
    calculateNights(dateout, dateinContract) {
      return dateout.diff(dateinContract, "days");
    },

    formatCurrency(amount, currency){
      return toCurrency(amount, currency)
    }
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
table.listPay th {
  padding: 3px;
}


table.listPay td {
  padding: 10px 21px 10px 21px;
}

table.listPay {
  text-align: center;
  font-size: 11px;
}

.modal .modal-header.bg-pagado {
  background-color: #28a745;

}

.bg-disponible {
  background-color: #17a2b8;

}

.modal .modal-header.bg-pendiente {
  background-color: #ffc107;

}

.modal .modal-header.bg-pendiente h5 {
  color: #000;
}

.modal .modal-header.bg-pagado h5 {
  color: #fff;
}

.buttonUpdate.col-md-2 {
  margin: 6px 0 0 0;
}

label.nightsValid {
  TEXT-ALIGN: center;
  display: block;
  /* background: red; */
  /* color: #fff; */
  font-size: 11px;
  margin: -15px 0 0 0;
  background: red;
  color: #fff;
}
</style>
