<template>
  <div v-if="estilo == 'md'" class="timer">
    <div>
      <div v-if="formato.days && days != 0" class="day">
        <span class="number">{{ days || 0 }}<br> {{days != 1 ? 'Días' : 'Día'}}</span>
      </div>
      <div v-if="formato.hours" class="hour">
        <span class="number">{{ hours || 0 }}<br> Hrs</span>
      </div>
      <div v-if="formato.minutes" class="min">
        <span class="number">{{ minutes || 0 }}<br> Min.</span>
      </div>
      <div v-if="formato.seconds" class="sec">
        <span class="number">{{ seconds || 0 }}<br> Seg.</span>
      </div>
    </div>
  </div>

  <div v-else-if="estilo == 'sm'">
    <div>
      <span v-if="formato.days && days != 0">{{ days }} {{days != 1 ? 'Días' : 'Día'}} </span>
      <span v-if="formato.hours && hours != 0">{{ hours }} Hrs. </span>
      <span v-if="formato.minutes">{{ minutes }} Min. </span>
      <span v-if="formato.seconds">{{ seconds }} Seg. </span>
    </div>
  </div>

  <div v-else-if="estilo == 'lg'">
    <div>
      <div v-if="formato.days && days != 0" class="block">
        <p class="digit">{{ days }}</p>
        <p class="text">{{days != 1 ? 'Días' : 'Día'}}</p>
      </div>
      <div v-if="formato.hours && hours != 0" class="block">
        <p class="digit">{{ hours }}</p>
        <p class="text">Hrs.</p>
      </div>
      <div v-if="formato.minutes" class="block">
        <p class="digit">{{ minutes }}</p>
        <p class="text">Min.</p>
      </div>
      <div v-if="formato.seconds" class="block">
        <p class="digit">{{ seconds }}</p>
        <p class="text">Seg.</p>
      </div>
    </div>
    <div v-if="formato.seconds" class="block">
    </div>
  </div>
</template>

<script>
export default {
  props:{
    starttime:{
      type: Date,
      required: false
    },
    expired:{
      type: String,
      required: false
    },
    running:{
      type: String,
      required: false
    },
    upcoming:{
      type: String,
      required: false
    },
    status:{
      type: Object,
      required: false
    },
    estilo:{
      type: String,
      required: false,
      default: 'Normal',
    },
    formato:{
      type: Object,
      required: false
    },
    stop:{
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      start: "",
      end: "",
      interval: "",
      days:"",
      minutes:"",
      hours:"",
      seconds:"",
      wordString: {
        expired: this.expired,
        running: this.running,
        upcoming: this.upcoming,
        status: {
          expired: "Expirado",
          running: "En curso",
          upcoming: "Proximamente"
        }
      },
      passTime:"",
    }
  },
  mounted(){
    this.start = this.starttime

    // Update the count down every 1 second
    this.timerCount(this.start)
    this.interval = setInterval(() => {
      this.timerCount(this.start)
    }, 1000);
  },
  methods: {
    timerCount(start,end){
      // Get todays date and time
      let now = new Date()

      // Find the distance between now an the count down date
      let distance = now - start;

      this.calcTime(distance)
    },
    calcTime(dist){
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000)
    }
  }
}
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.text {
  color: #1abc9c;
  font-size: 40px;
  /* font-family: 'Roboto Condensed', serif; */
  font-weight: 40;
  margin-top:10px;
  margin-bottom: 10px;
  text-align: center;
}

.digit {
  color: #ecf0f1;
  font-size: 150px;
  font-weight: 100;
  /* font-family: 'Roboto', serif; */
  margin: 10px;
  text-align: center;
}
</style>

<style lang="scss" scoped>
.timer {
  font-size: 10px;
  color: #fff;
  text-align:center;
  margin-top: 15px;

      .day, .hour, .min, .sec {
        font-size: 15px;
        display: inline-block;
        font-weight: 500;
        text-align: center;
        margin: 0 5px;
        .format {
          font-weight: 300;
          font-size: 14px;
          //@include margin-start(5);
          //display: inline-block;
          opacity: 0.8;
          width: 60px;
        }
      }
      .number{
        background: rgba(51, 51, 51, 0.53);
        padding: 0 5px;
        border-radius: 5px;
        display: inline-block;
        width: 60px;
        text-align: center;
      }
      .message {
        color:#000;
        font-size: 11px;
        font-weight: 400;
        margin-top: 5px;
      }
      .status-tag{
        width: 270px;
        margin: 10px auto;
        padding: 8px 0;
        font-weight: 500;
        color: #000;
        text-align: center;
        border-radius: 15px;
        &.upcoming{
          background-color: lightGreen;
        }
        &.running{
          background-color: gold;
        }
        &.expired{
          background-color: silver;
        }
      }
    }
</style>