import Vue from 'vue'

import { handlerErrors, consoleMsgFinally, toJson, showAlertMessage } from '../../../helpers/helpers'
import { fetchHotels } from '../../start/store/actions'
import store from '../../../store'


export async function getInitialContentCheckout( context ){  
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }                       
    const content = await Promise.all([        
        fetchHotels(payloadHotels), fetchUsersByChannel(), fetchTypeGroups(), //make 3 requests
    ])    
    const [ hotels, usersChannel, groupTypes ] = content  
   
    context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start      
    context.commit('mySales/setUsersChannel', usersChannel, { root: true }) //muto state mySales/setUsersChannel    
    context.commit('setTypeGroups', groupTypes ) //muto state typeGroups            
    return content
}


export async function fetchUsersByChannel() {
    const idCanal = store.state.auth.user.canalventa.id
    try {
        
        const response = await Vue.axios({
            url: '/catalogs/usuariosCanal/',
            params: {
                idCanal
            }
        })
        const payload = response && response.data
        payload.forEach((user) => {
            user.value = user.id,
            user.text = user.first_name + ' ' + user.last_name
        })
        return payload 

    } catch (error) {
        console.log( error )
    } finally {        
        consoleMsgFinally('groups/fetchUsersByChannel', 'La petición para obtener los usuarios del canal se ha terminado')
    }
}

export async function fetchTypeGroups() {
    try {        
        const response = await Vue.axios({
            url: 'grupos/typeGroups/'
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {        
        consoleMsgFinally('setTypeGroups', 'La petición para obtener los estados se ha terminado')
    }
}


export async function fetchRooms({ commit },data) {
    try {
        commit('groups/setLoadingProcess', true, { root: true })
        let response

        if(data==1){
            response = await Vue.axios({
                url: 'grupos/gruposBooking/'
            })
        } else {
            const { typegroup, ffin, fini, hotel } = data
            if (fini!=''  && ffin!=''){
                response = await Vue.axios({
                    url: 'grupos/gruposBooking/',
                    params: {
                        typegroup: typegroup,
                        fini: fini,
                        ffin:ffin,
                        hotel:hotel
                    }
                })
            }

            if(fini==''|| ffin==''){
                response = await Vue.axios({
                    url: 'grupos/gruposBooking/',
                    params: {
                        typegroup: typegroup,
                        hotel:hotel
                    }
                })
            }
        }

        const payload = response && response.data

        payload.forEach((roomsGroup) => {
            roomsGroup.rooms.data.forEach((rooms) => {
                rooms.book=[]
                rooms.open=false
                rooms.roomsTypes=[]
                rooms.isloadvalid=false
            })

        })
        commit('setRooms', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setRooms', [])
        commit('setRoomsError', errorMsg)
        showAlertMessage(
            'No se pudieron obtener los grupos', 'InfoIcon',
            'Hubo un error al obtener los grupos, por favor inténtelo más tarde o contacte a soporte',
            'warning', 4000, 'bottom-right'
        )
        return []
    } finally {
        commit('groups/setLoadingProcess', false, { root: true })
        consoleMsgFinally('setRooms', 'La petición para obtener los estados se ha terminado')
    }
}




export async function fetchRoomsId({ commit },id) {
    try {
        let response

        response = await Vue.axios({
            url: 'grupos/gruposBooking/',
            params: {
                id: id
            }
        })

        const payload = response && response.data

        payload.forEach((roomsGroup) => {
            roomsGroup.rooms.data.forEach((rooms) => {
                rooms.book=[]
                rooms.open=false
                rooms.roomsTypes=[]
                rooms.isloadvalid=false
            })

        })

        return payload

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setRoomsError', errorMsg)
        return []
    } finally {
        consoleMsgFinally('setRooms', 'La petición para obtener los estados se ha terminado')
    }
}


export async function fetchRoomsUpdate({ commit },data) {
    try {
        let response

        if(data==1){
            response = await Vue.axios({
                url: 'grupos/gruposBooking/'
            })
        } else {
            const { typegroup, ffin , fini,hotel } = data
            if(fini!=''  && ffin!=''){
                response = await Vue.axios({
                    url: 'grupos/gruposBooking/',
                    params: {
                        typegroup: typegroup,
                        fini: fini,
                        ffin:ffin
                    }
                })
            }

            if(fini==''|| ffin==''){
                response = await Vue.axios({
                    url: 'grupos/gruposBooking/',
                    params: {
                        typegroup: typegroup,
                        hotel:hotel
                    }
                })
            }
        }

        const payload = response && response.data

        payload.forEach((roomsGroup) => {
            roomsGroup.rooms.data.forEach((rooms) => {
                rooms.book=[]
                rooms.open=false
                rooms.roomsTypes=[]
                rooms.isloadvalid=false
            })
        })

        commit('setRooms', payload)
        const { status } = payload
        if (status) {
            showAlertMessage(
                'Se actualizo la reserva', 'InfoIcon',
                '🎉🎉La actualización ha sido exitosa',
                'success', 4000, 'bottom-right'
            )
        }
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setRoomsError', errorMsg)
        commit('setRooms', [])
        showAlertMessage(
            'No se pudieron obtener los grupos', 'InfoIcon',
            'Hubo un error al obtener los grupos, por favor inténtelo más tarde o contacte a soporte',
            'warning', 4000, 'bottom-right'
        )
        return []
    } finally {
        consoleMsgFinally('setRooms', 'La petición para obtener los estados se ha terminado')
    }
}

export async function fetchRoomsExcel({ commit },data) {
    const { ffin,fini,typegroup,hotel,fileName } = data
    try {
   
        const response = await Vue.axios({
            url: `grupos/roomlistExcel/`,
            responseType:'blob',
            params: {
                typegroup,
                fini,
                ffin,
                hotel
           

            }
        })
        const payload = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        commit('setRoomsDetail', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setsetRoomsDetailError', errorMsg)
    } finally {
 
        consoleMsgFinally('setTypeGroups', 'La petición para obtener los estados se ha terminado')
    }
}

export async function fetchRoomBooking({ commit },datos) {
    
    const { idGroup, idRoom,datein,dateout,idRoomOnly } = datos
    try {
    
        const response = await Vue.axios({
            url: 'grupos/groupsroomsbooking/',
            params: {
                idGroup: idGroup,
                idRoom: idRoom,
                idRoomOnly: idRoomOnly,
                datein: datein,
                dateout:dateout
            }
        })
        let payload = response && response.data
        payload.forEach((roomsGroup) => {
          
                roomsGroup.numberChildren=0
                roomsGroup.ninos= []
                roomsGroup.note
                roomsGroup.nightsPay=0
                             
                if(roomsGroup.nameprefix=='Wedding' || roomsGroup.nameprefix=='Grupos' ){
                    roomsGroup.nameprefix='0' 
                }
               
        })
        if(payload.length==0){
            payload.group=idGroup,
            payload.room= idRoom, 
            payload.datein =datein    
            payload.status='delete'
          
        }else{
            
            payload.status='load'
            
        }
        commit('setRoomBooking', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setRoomBookingError', errorMsg)
    } finally {
      
        consoleMsgFinally('setRoomBooking', 'La petición para obtener los estados se ha terminado')
    }
}


export async function fetchRoomBookingInfo(context,datos) {
    const { idGroup, idRoom,datein,dateout,idRoomOnly } = datos
    try {
        const response = await Vue.axios({
            url: 'grupos/groupsroomsbooking/',
            params: {
                idGroup: idGroup,
                idRoom: idRoom,
                idRoomOnly: idRoomOnly,
                datein: datein,
                dateout:dateout,
                getAvailable: datos.getAvailable
            }
        })
        let payload = response && response.data
        payload.forEach((roomsGroup) => {
          
                roomsGroup.numberChildren=0
                roomsGroup.ninos= []
                roomsGroup.note
                roomsGroup.nightsPay=0
                             
                if(roomsGroup.nameprefix=='Wedding' || roomsGroup.nameprefix=='Grupos' ){
                    roomsGroup.nameprefix='0' 
                }
               
        })
        if(payload.length==0){
            payload.group=idGroup,
            payload.room= idRoom, 
            payload.datein =datein    
            payload.status='delete'
          
        }else{
            
            payload.status='load'
            
        }

        return payload
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchRoomBookingInfo end')
    }
}


export async function fetchRoomsDetail({ commit },idGroup) {

    try {
        commit('groups/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            method: 'POST',
            url: `grupos/groupDataBooking/`,
            data: idGroup
        })
        const payload = response && response.data

        commit('setRoomsDetail', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setsetRoomsDetailError', errorMsg)
    } finally {
        commit('groups/setLoadingProcess', false, { root: true })
        consoleMsgFinally('setTypeGroups', 'La petición para obtener los estados se ha terminado')
    }
}

export async function fetchRoomsTypes({ commit },idHotel) {

    try {
        const response = await Vue.axios({
            url: 'catalogs/roomsType/',
            params: {
                idHotel: idHotel
            }
        })
        const payload = response && response.data
        payload.forEach((types) => {
            types.value = types.id
            types.text = types.name
          
        })    
        commit('setRoomsTypes', payload)

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchRoomsTypes end')
    }
}

export async function getRoomTypesHousing(context, idHotel ) {

    try {
        const response = await Vue.axios({
            url: 'catalogs/roomsType/',
            params: {
                idHotel: idHotel
            }
        })
        const payload = response && response.data
        payload.forEach((types) => {
            types.value = types.id
            types.text = types.name
          
        })    
        return payload        

    } catch (error) {
        console.log(error)
    } finally {
        console.log('getRoomTypesHousing end')
    }
}

export async function bookingModifiedOwner({ commit },booking) {
    let objetoDetalle =[]
    objetoDetalle=booking
    try {
    
        const response = await Vue.axios({
            method: 'POST',
            url: `fivesClub/bookingModifiedOwner/`,
            data: objetoDetalle
        })

        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            showAlertMessage(
                'Se actualizo la reserva', 'InfoIcon',
                '🎉🎉La actualización ha sido exitosa',
                'success', 4000, 'bottom-right'
            )
        }

    } catch (error) {        
        showAlertMessage(`No se pudo realizar la reserva`, 'InfoIcon', `😰😰 No se encuentra las transacciones `,'danger', 3000, 'bottom-right')
    } finally {

        consoleMsgFinally('bookingModifiedOwner', 'end')
    }
}
export async function fetchUpdateRoomsDetail({ commit },booking) {
    let objetoDetalle =[]
    objetoDetalle={"orderbooking":booking}

    try {
    
        const response = await Vue.axios({
            method: 'POST',
            url: `grupos/bookingGroup/`,
            data: objetoDetalle
        })

        const respuesta = response && response.data
        const { status } = respuesta
        if (status) {
            showAlertMessage(
                'Se actualizo la reserva', 'InfoIcon',
                '🎉🎉La actualización ha sido exitosa',
                'success', 4000, 'bottom-right'
            )
        }

        return status

    } catch (error) {
        const errorMsg = handlerErrors(error?.message, error?.response)
        commit('setsetRoomsDetailError', errorMsg)
        return false
    } finally {
        consoleMsgFinally('setTypeGroups', 'La petición para obtener los estados se ha terminado')
    }
}

export async function fetchDataOrderBooking({ commit },idBooking) {

    try {
        
        const response = await Vue.axios({
            url: 'grupos/dataBookingPay/',
            params: {
                idBooking: idBooking
            }
        })
        const payload = response && response.data
        
        commit('setRoomsInfo', payload[0])

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setsetRoomsInfoError', errorMsg)
    } finally {
       
        consoleMsgFinally('setRoomsInfo', 'La petición para obtener los estados se ha terminado')
    }
}


export async function fetchTransactionsBooking({ commit },idBooking) {

    try {
        
        const response = await Vue.axios({
            url: 'payment/orderTransactionsBookings/',
            params: {
                id: idBooking
            }
        })
        const payload = response && response.data
        
        commit('setRoomsTransactions', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setRoomsTransactionsError', errorMsg)
    } finally {
       
        consoleMsgFinally('setRoomsTransactions', 'La petición para obtener los estados se ha terminado')
    }
}


export async function fetchBookingOnly({ commit },idBooking) {
    try {
        const response = await Vue.axios({
            url: 'grupos/dataBookingPay/',
            params: {
                idBooking: idBooking
            }
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchBookingOnly end')
    }
}


export async function fetchCancelRoom({ commit },datos) {
  
    try {
    
        const response = await Vue.axios({
            method: 'POST',
            url: `grupos/cancelBookingGroup/`,
            data: datos
        })
        const respuesta = response && response.data
        const { status } = respuesta

        if (status) {
            showAlertMessage(
                'Se canceló la reserva', 'InfoIcon',
                '🎉🎉La cancelación ha sido exitosa',
                'success', 4000, 'bottom-right'
            )
        }

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        showAlertMessage('Proceso terminado', 'InfoIcon', `😪😪 ${errorMsg}`, 'warning', 4000, 'bottom-right')
        commit('setsetRoomsDetailError', errorMsg)
    } finally {

        consoleMsgFinally('fetchCancelRoom', 'La petición para obtener los estados se ha terminado')
    }
}

export async function fetchRoomsLanding({ commit },data) {
    try {
        commit('groups/setLoadingProcess', true, { root: true })
        let response

        const { id } = data
           
             response = await Vue.axios({
                url: 'grupos/grupoInfo/',
                params: {
                    idGroup: id,
                 
    
                }
            })
    
        const payload = response && response.data
      
       
        commit('setGroups', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setRoomsError', errorMsg)
    } finally {
        commit('groups/setLoadingProcess', false, { root: true })
        consoleMsgFinally('setRooms', 'La petición para obtener los estados se ha terminado')
    }
}

export async function fetchLoginLanding({ commit },data) {
    try {
        commit('groups/setLoadingProcess', true, { root: true })
        let response

        const { email } = data
        let encodedStr = email.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
            return '&#'+i.charCodeAt(0)+';';
         });
             response = await Vue.axios({
                url: 'userManager/',
                params: {
                    emailUser: encodedStr,
                 
    
                }
            })
    
        const payload = response && response.data
        commit('groups/setUserLandingTrue',false, { root: true})    
       
        commit('setUserLanding', payload)

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setUserLanding', [])
        commit('setRoomsError', errorMsg)
    } finally {
        commit('groups/setLoadingProcess', false, { root: true })
        consoleMsgFinally('setRooms', 'La petición para obtener los estados se ha terminado')
    }
}



export async function fetchAuthorization({ commit },datos) {
  
    try {
    
        const response = await Vue.axios({
            method: 'PUT',
            url: `grupos/grupoInfo/`,
            data: datos
        })
        const respuesta = response && response.data
      

        
            showAlertMessage(
                'Se actualizo la cotizacion', 'InfoIcon',
                '🎉🎉La actualización ha sido exitosa',
                'success', 4000, 'bottom-right'
            )
    
       
    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        showAlertMessage('Proceso terminado', 'InfoIcon', `😪😪 ${errorMsg}`, 'warning', 4000, 'bottom-right')
        commit('setsetRoomsDetailError', errorMsg)
    } finally {

        consoleMsgFinally('fetchAuthorization', 'La petición para obtener los estados se ha terminado')
    }
}



export async function sendUnlockGroup({ commit },room) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: 'grupos/unlockGroup/',
            data: room
            
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('sendUnlockGroup end')
    }
}

export async function fetchRatesGroup({ commit },booking) {
    let objetoDetalle =[]
    objetoDetalle={"orderbooking":booking}
    try {
    
        const dataResponse = await Vue.axios({
            method: 'POST',
            url: `grupos/getGroupRate/`,
            data: objetoDetalle
        })

        const respuesta = dataResponse && dataResponse.data
        const { response } = respuesta
        return response

    } catch (error) {
        const errorMsg = handlerErrors(error.message, error.response)
        commit('setsetRoomsDetailError', errorMsg)
    } finally {

        consoleMsgFinally('setTypeGroups', 'La petición para obtener los estados se ha terminado')
    }
}


export async function fetchCancelMotive({commit},data) {
    try {
        const { id } = data
        const response = await Vue.axios({
            url: 'grupos/getCancelMotive/',
            params: {
                idCanal: id
            }
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchCancelMotive end')
    }
}

export async function sendAllomentRoom({ commit },room) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: 'grupos/allomentRoom/',
            data: room
            
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('sendUnlockGroup end')
    }
}




export function createObjectBooking(item) {

       let objetoDetalle={
        orderbooking:{

            bookingid:item.id,
            hotel: item.hotel,
            urlimage:"",
            dateIn:item.datein,
            dateOut: item.dateout,
            adults: item.adults,
            children: item.children,
            total: item.total,
            email: item.email,
            givenname: item.givenname,
            surname: item.surname,
            nameprefix: item.nameprefix,
            phonenumber: item.phonenumber,
            language: item.language,
            vendor: item.vendorcode,
            roomtypecode: item.roomCategory[0].code,
            roomtypename:item.roomCategory[0].name,
            mealplancode: item.mealplancode,   
            termscondition: null,     
            promotioncode: null,
            rateplanid: null,
            rateplancode: null,
            rateplanname: null,
            dingusbooking: null,
            rateInit:item.rateInit,
            rateManual: 0,
            isAuthorization: 0,
            authorizationUser: null,        
            urlAuthorization: null,
            inclusions: null,
            ninos: toJson(item.booking.ninios),
            groupId:item.group,
            roomId:item.room,
            paymentParcial:(item.orderdetail.firstpay != 0)? item.orderdetail.firstpay:item.total,
            nights:item.nights,
            nightspay:item.orderdetail.nightspay,
            partial: (item.orderdetail.firstpay != 0) ? 1: 0,
            guestReserved :1
          
       
        }  
    }
   
    return objetoDetalle
}