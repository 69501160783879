<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        > <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex" v-if="showMainNavbar">

      <ul class="nav navbar-nav" v-if="windowWidth > 774">
        <li class="nav-item" >
          <router-link :to="{ name: 'booking' }" class="nav-link" :class="activeClass('booking')">
            <span> <feather-icon icon="HomeIcon" size="16" /> Estancias</span>
          </router-link>
        </li>
        <li class="nav-item">
          <b-link :to="{ name: 'sales' }" class="nav-link" :class="activeClass('sales')">
            <span> <feather-icon icon="ThumbsUpIcon" size="16" /> Experiencias</span>
          </b-link>
        </li>
        <li class="nav-item">
          <b-link :to="{ name: 'transfer' }" class="nav-link" :class="activeClass('transfer')">
            <span> <feather-icon icon="BriefcaseIcon" size="16" /> Traslados </span>
          </b-link>
        </li>
        <li class="nav-item">
          <b-link :to="{ name: 'order-summary' }" class="nav-link" :class="activeClass('order-summary')">
            <span> <feather-icon icon="ShoppingCartIcon" size="16" /> Resumen Orden</span>
          </b-link>
        </li>
        <li class="nav-item" v-if="requiresFillCartData">
          <b-link :to="{ name: 'payment' }" class="nav-link" :class="activeClass('payment')">
          <span> <feather-icon icon="CreditCardIcon" size="16" /> Finalizar Venta </span>
          </b-link>
        </li>
      </ul>

      <b-dropdown
        v-if="windowWidth < 775"
        id="dropdown-8"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="link"
        class="items-web-color"
      >
        <template #button-content>
          <feather-icon :icon="currentLocation" size="16" class="items-web-color"/>
        </template>
        <b-dropdown-item :to="{ name: 'booking' }"  > <feather-icon icon="HomeIcon" size="16" /> Estancias</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'sales' }"> <feather-icon icon="ThumbsUpIcon" size="16" /> Experiencias</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'transfer' }" v-show="false"> <feather-icon icon="BriefcaseIcon" size="16" /> Traslados</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'order-summary' }"> <feather-icon icon="ShoppingCartIcon" size="16" /> Resumen</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'payment' }" v-if="requiresFillCartData"> <feather-icon icon="CreditCardIcon" size="16" /> Finalizar</b-dropdown-item>
      </b-dropdown>

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <CurrencyDropdown v-if="showMainNavbar"/>
      <SaleChannelDropdown :isreLoading="isreLoading" @set-loader-channels="setIsReloading" v-if="showMainNavbar" />
      <cart-dropdown id="cartItemVertical" v-if="($route.name != 'order-summary') && showMainNavbar" class="pr-half"/>
      <user-dropdown v-if="!isreLoading"/>
      <b-skeleton type="avatar" class="mr-half ml-half" v-else></b-skeleton>
    </b-navbar-nav>

  </div>
</template>

<script>

import {  mapState, mapGetters } from 'vuex'
import CartDropdown from './components/CartDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import CurrencyDropdown from './components/CurrencyDropdown.vue'
import SaleChannelDropdown from './components/SaleChannelDropdown.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    CartDropdown,
    UserDropdown,
    SaleChannelDropdown,
    CurrencyDropdown
  },
  data(){
    return{
      isreLoading: false,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed:{
    ...mapState('appConfig',['layout','windowWidth']),
    ...mapGetters('appConfig',['currentBreakPoint']),
    ...mapState("shop", ["cart"]),
    ...mapState('auth',['permissions']),

    currentLocation(){
      if (this.$route.name == 'booking') return 'HomeIcon'
      if (this.$route.name == 'sales') return 'ThumbsUpIcon'
      if (this.$route.name == 'transfer') return 'BriefcaseIcon'
      if (this.$route.name == 'order-summary') return 'ShoppingCartIcon'
      if (this.$route.name == 'payment') return 'CreditCardIcon'
    },
    showMainNavbar(){
      return this.permissions.includes("marketplace_menu_show_marketplace")
    },
    requiresFillCartData(){
      return this.cart?.items.length > 0 && this.cart?.requiredFields?.length == 0
    }
  },
  methods:{
    activeClass: function (...names) {
      for (let name of names){
        if (name == this.$route.name){
          const itemActive = this.layout.skin === 'light' ? 'item-active-ligth': 'item-active-dark'
          return itemActive
        }
      }
    },
    setIsReloading(boolean){
      this.isreLoading = boolean
    }
  }
}
</script>


<style scoped>
  .item-active-ligth {
    /* background-color: #eceff1; */
    /* border-radius: 5px; */
    border-bottom: 2px solid black;
  }
  .item-active-ligth span {
    color: black;
    font-weight: bold;
  }
  .item-active-dark {
    /* background-color: #eceff1; */
    /* border-radius: 5px; */
    border-bottom: 2px solid white;
  }
  .item-active-dark span {
    color: white;
    font-weight: bold;
  }
  .break-word{
    word-break: break-all;
  }
  .cash-height{
    height: 3rem;
  }
  .btn.dropdown-toggle{
    padding: 0;
  }
</style>