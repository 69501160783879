import isAuthenticatedGuard from '../../auth/router/auth-guard'

export default [
    {
        path: '/sales/products',
        name: 'sales',
        component: () => import('@/modules/shop/views/Experiencies'),
        meta: {
            requiresAuth: true,
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
            permissions: ['marketplace_menu_show_marketplace']
        },
    },
    {
        path: '/sales/products/:slug',
        name: 'sales-product-details',
        component: () => import('@/modules/shop/views/DetailExperience'),
        meta: {
            requiresAuth: true,
            permissions: ['marketplace_menu_show_marketplace']
        },
        props: ( route ) => {
            return {
                slug: route.params.slug
            }
        },
    },
    {
        path: '/sales/productsExperiences/:slug',
        name: 'experience-details',
        component: () => import('@/modules/shop/views/DetailExperienceRate'),
        meta: {
            requiresAuth: true,
            permissions: ['marketplace_menu_show_marketplace']
        },
        props: ( route ) => {
            return {
                slug: route.params.slug
            }
        },        
    }, 
    {
        path: '/sales/order',
        name: 'order-summary',
        component: () => import('@/modules/shop/views/OrderSummary'),
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
            permissions: ['marketplace_menu_show_marketplace']
        },
    },
    {
        path: '/sales/transfer',
        name: 'transfer',
        component: () => import('@/modules/shop/views/Transfer'),
        meta: {
            requiresAuth: true,
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'transfer-application',
            permissions: ['marketplace_menu_show_marketplace']
        },
    },
    {
        path: '/sales/payment',
        name: 'payment',
        component: () => import('@/modules/shop/views/Checkout'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
            permissions: ['marketplace_menu_show_marketplace']
        },
    },
    {
        path: '/sales/booking',
        name: 'booking',
        component: () => import('@/modules/shop/views/Bookings'),
        meta: {
            requiresAuth: true,
            contentClass: 'ecommerce-application',
            // permissions: ['bookings_menu_show_booking']
        },
    },
    {
        path: '/sales/bankresponse',
        name: 'bank-response',
        component: () => import ('@/modules/shop/views/BankResponse'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/paymentlanding',
        name: 'payment-response',
        // component: () => import ('@/modules/shop/views/PaymentLanding'),
        component: () => import ('@/modules/shop/views/PaymentPage.vue'),

        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/paymentbooking',
        name: 'paymentbook-response',
        component: () => import ('@/modules/shop/views/PaymentBooking'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/paymentbookinggroup',
        name: 'paymentbookgroup-response',
        component: () => import ('@/modules/shop/views/PaymentBookingGroup'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/itinerario',
        name: 'itinerario',
        component: () => import ('@/modules/shop/views/Itinerario'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/termsConditions',
        name: 'termsConditions',
        component: () => import ('@/modules/shop/views/TermsConditions'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/payment/response',
        name: 'paymentresponse',
        component: () => import ('@/modules/shop/views/Response.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/sales/authorizationgroup',
        name: 'authorizationgroup',
        component: () => import ('@/modules/shop/views/AuthorizationGroup.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import ('@/modules/shop/views/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },

]