<template>
  <div>
  
    <b-col>
      <div class="float-right mt-3">
        <b-button
          variant="primary"
          @click="updateMyBooking"
          :disabled="!room.showingRates || room.upgradesRoomsInfo == 0"
        ><b-spinner small v-if="isUpdating"/> Actualizar
        </b-button>
      </div>
    
    </b-col>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { toJson } from "@/helpers/helpers";
import { makeParamsOwners } from "@/helpers/fivesClubHelper";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      isUpdating:false,
      imgUrl: `${process.env.VUE_APP_IMG_SRC_API}/media/imgdefault.jpg`,
    }
  },
  components: {},
  computed: {
    ...mapState("fivesClubCatalogs", ["authorization", "bookingInfo","authorizationList","reasonAuthorization", "owners" ]),
    ...mapState("auth", ["user"]),
    ...mapState("mySales", ["searchForm"]),
    ...mapState("start", ["hotels"]),
  },
  methods: {
    ...mapActions("fivesClubCatalogs", [
      "saveOwnerBooking",
      "getOwnerBookingBalck",
      "updateOwnerBooking",
    ]),
    ...mapActions("groups", ["bookingModifiedOwner"]),
    ...mapMutations("shop", ["setInfoClient"]),
    ...mapMutations("bookings", ["setRooms"]),

    async updateMyBooking() {
      this.isUpdating=true
      const searchForm = toJson(this.searchForm)
      const { blackout,  selectedBenefit, vendorcode,  invitedAdults, owner} = searchForm
      const {detailSelected, multimedias, RoomTypeCode, RoomTypeName, priceInit, upgradesRoomsInfo} = toJson(this.room)
      const {InfoRates, RatePlanCode,  AmountAfterTax, PromotionCode, RatePlanID, TotalOffersApplied, manualPrice, MealPlanDescription} = detailSelected

      const payload = {        
        categoryName: "ReservasOwners",
        descmax: 0,
        detaildisplay: `${RoomTypeCode} | ${RoomTypeName}`,
        detailId: RoomTypeCode,
        extrapax: false,
        id: this.bookingInfo.id,
        idBooking: this.bookingInfo.id,
        img: (multimedias.length > 0 && multimedias[0].multimedia != '' ) ? multimedias[0].multimedia : this.imgUrl,
        infoRates: InfoRates,
        rateInit: Math.abs(TotalOffersApplied),
        priceInit:priceInit,
        ...detailSelected,
        modelType: "booking",
        multimedias: multimedias.length > 0 ? toJson(multimedias) : null,
        isEdit: this.bookingInfo.isEdit || 'false',
        vendorcode,
        promoCode: PromotionCode,
        rateManual: manualPrice,
        rateplanid: RatePlanID,
        rateplanname: MealPlanDescription,
        benefitSelected: selectedBenefit.benefit,
        benefitNights: selectedBenefit.balance,
        used: selectedBenefit.used,
        needAuthorization: this.authorization,
        isEditBooking:false,
        reasonAuthorization: this.reasonAuthorization,
        balanceNights: 0,
        blackout: blackout,
        bookingInfo: this.bookingInfo,
        roomtypename: RoomTypeName,
        saleprice: AmountAfterTax,
        serviciosihot: null,
        tarifa: RatePlanCode,
        email: invitedAdults[0].email,
        name: invitedAdults[0].name,
        lastname: invitedAdults[0].lastname,
        phone: invitedAdults[0].phone,
        term: { id: 8, title: "	Política Propietarios" },
        userId: this.user.idUser,
        ...searchForm,
        nightsUpgrade: upgradesRoomsInfo,
      }

      payload.reasonAuthorization = this.reasonAuthorization
     
      const payloadNames = {
        children: payload.children,
        infoAdults: payload.infoAdults,
      }

      const list = makeParamsOwners(payloadNames)
      let dataRoom = {
        userId: this.user.idUser,
        id: payload.id,
        idBooking: this.bookingInfo.id,
        owner: owner.id,
        housing: this.owners.housing.length ? this.owners.housing[0].idHousing : 0,
        dateIn: payload.dateIn,
        dateOut: payload.dateOut,
        nights: payload.nights == null ? payload.bookingInfo.nights : payload.nights,
        adults: payload.adults == null ? payload.bookingInfo.adults : payload.adults,
        children: payload.numberChildren == null ? payload.bookingInfo.numberChildren : payload.numberChildren,
        internalnotes: payload.internalnotes,
        status: 2,
        needAuthorization: this.authorization,
        reasonAuthorization: this.reasonAuthorization,
        isguest: payload.isguest,
        origin: payload.origin,
        occupanttype: payload.occupanttype,
        reservetype:  payload.bookingInfo.reservetype != 1 ? this.authorizationList.additional : payload.bookingInfo.reservetype ,
        isEdit: this.bookingInfo.isEdit || 'false',
        infoBeneficiaries: list,
        canChangeSatus:this.bookingInfo.canChangeSatus
      }
      
      const idOwnerBooking = await this.updateOwnerBooking(dataRoom);
      payload.idOwnerBooking = idOwnerBooking

      await this.bookingModifiedOwner(payload)

      this.isUpdating=false
      this.$emit('refresh-order')
    },   
  },
};
</script>