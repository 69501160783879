<template>
  <div>
    <b-col md="12" class="p-0" v-if="costsAreBlocked">
      <b-alert variant="danger" show>
        <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />
          <span><strong>¡Bloqueado!</strong> Ya se encuentra bloqueado.</span>
        </div>
      </b-alert>
    </b-col>

    <b-col md="12" class="p-0" v-if="errorsMsgMonth !='' ">
      <b-alert variant="danger" show>
        <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />
          <strong>{{ errorsMsgMonth }} </strong> 
        </div>
      </b-alert>
    </b-col>

    <b-col md="12" class="p-0">
      <div v-if="!profitData.length && isEmpty">
        <b-alert variant="warning" show>
          <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />
            <span>Haga una busqueda mediante el filtro</span>
          </div>
        </b-alert>
      </div>
      <div v-if="!profitData.length && !isEmpty">
        <b-alert variant="warning" show>
          <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" />
            <span><strong>¡No hay registros!</strong> ¿Quiere calcular?</span>
          </div>
        </b-alert>
      </div>
      <b-row class="mb-3">
        <b-col v-if="profitData.length && !isEmpty">
          <b-row>
            <b-col md="4">
              <label>Numero de registros</label>
              <v-select
                v-model="porPagina"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector"
                @input="changePerPage(porPagina)"
              />
            </b-col>
            <b-col md="">
              <label>Buscar en tabla</label>
              <div class="d-flex">
                <b-form-input
                  class="mr-1"
                  placeholder="Buscar..."
                  type="search"
                  v-model="filter"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <div class="float-right mt-2 mr-2" v-if="profitData.length && !isEmpty">
            <b-button
              variant="success"
              class="btn fives-club-excel-btn ml-1"
              @click="downloadExcel"
              :disabled="!profitData.length || isLoadingExcel"
              ><feather-icon icon="FileTextIcon" /> Excel
              <b-spinner small label="Loading..." v-if="isLoadingExcel" />
            </b-button>
          </div>
          <div class="float-right mt-2" v-if="!profitData.length && !isEmpty">
            <b-button
              :disabled="isLoadingExcel"
              variant="primary"
              v-b-tooltip.hover
              title="Calcular"
              @click="calculateProfit"
              class="btn btn-success"
            >
              <b-spinner small v-if="isSavingProfit" /> Calcular
            </b-button>
          </div>
          <div class="float-right mt-2" v-if="!costsAreBlocked && profitData.length">
            <b-button
							v-can="'fivesclub_profit_close_calculating_performance'"
              :disabled="isLoadingExcel"
              variant="primary"
              v-b-tooltip.hover
              title="Cerrar"
              @click="saveProfit"
              class="btn btn-danger"
            >
              <b-spinner small v-if="isSavingProfit" /> Cerrar Calculo
            </b-button>
          </div>
          <div class="float-right mt-2 mr-2" v-if="!costsAreBlocked && profitData.length">
            <b-button
							v-can="'fivesclub_profit_allow_calculating_performance'"
              :disabled="isLoadingExcel"
              variant="primary"
              v-b-tooltip.hover
              title="Calcular"
              @click="reCalculateProfit"
              class="btn btn-success"
            >
              <b-spinner small v-if="isSavingProfit" />Re Calcular
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-col>

    <b-table
      v-if="profitData.length"
      :items="profitData"
      sticky-header="600px"
      responsive
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      primary-key="id"
      show-empty
      empty-text="Haga una busqueda mediante el filtro"
      class="position-relative mb-0"
      :busy.sync="isSavingProfit"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell()="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>
      <template #empty="scope">
        <b-alert variant="warning" :show="true" class="mb-0">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            <span>{{ scope.emptyText }}</span>
          </div>
        </b-alert>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando datos</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { toDecimal } from "@/helpers/helpers"
import vSelect from "vue-select";
export default {
  mixins: [utils],
  props: {
    profitData: {
      type: Array,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
    isLoadingCosts: {
      type: Boolean,
      required: false,
    },
    isEmpty: {
      type: Boolean,
      required: false,
    },
    isLoadingExcel: {
      type: Boolean,
      required: false,
    },
    perPage: {
      type: Number,
      required: true,
    },
    errorsMsgMonth: {
      type: String,
      required: true,
    },
    perPageOptions:{
      type: Array,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      isSavingProfit: false,
      porPagina: this.perPage,
      filter: '',
      filterOn: ['contractcode', 'ownercode', 'ownername', 'housingnumber'],
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubProfit", ["costsAreBlocked"]),
  },
  methods: {
    changePerPage(perPage) {
      this.$emit("change-per-page", perPage)
      this.porPagina = perPage
    },
    async saveProfit() {
      const isConfirmed = await this.confirmClos()
      if (isConfirmed) this.$emit("save-profit")
    },
    async calculateProfit() {
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) this.$emit("calculate-profit")
    },
    async reCalculateProfit() {
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) this.$emit("recalculate-profit")
    },
    async confirmClos() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        text: "Seguro que quiere Cerrar",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
      })
      return isConfirmed
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        text: "Seguro que calcular",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
      })
      return isConfirmed
    },
    formatDecimal(number) {
      return toDecimal(number)
    },
    async downloadExcel() {
      this.$emit("export-profit")
    },
  },
}
</script>

<style lang="scss" >
.display-6 {
  font-size: 13px;
}
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered {
    align-content: center;
    align-items: center;
  }
  .isSpinner {
    padding: 2rem;
  }
}
</style>
