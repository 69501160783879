var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-row',{staticClass:"mt-1",attrs:{"align-v":"center"}},[(!_vm.isEdition)?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"centro de consumo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Centro de consumo*"}},[_c('b-form-select',{staticClass:"font-weight-bolder",attrs:{"state":errors[0] ? false : valid ? true : null,"disabled":_vm.isLoadingGuests},model:{value:(_vm.guest.consumptionCenter),callback:function ($$v) {_vm.$set(_vm.guest, "consumptionCenter", $$v)},expression:"guest.consumptionCenter"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.consumptionCenters),function(c){return _c('option',{key:c.id,domProps:{"value":c.id}},[_vm._v(" "+_vm._s(c.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,false,2593556998)})],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":""}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"habitación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Habitación*"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"disabled":_vm.isLoadingGuests,"placeholder":"N° Habitación","state":errors[0] || !valid ? false : null},on:{"keypress":_vm.onlyNumbersLetters},model:{value:(_vm.guest.room),callback:function ($$v) {_vm.$set(_vm.guest, "room", $$v)},expression:"guest.room"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),(_vm.consumptionCenter && !!_vm.consumptionCenter.hotel)?_c('b-col',{staticClass:"mt-1 pl-0 text-right",attrs:{"md":"3"}},[_c('b-button',{attrs:{"disabled":!_vm.guest.room || _vm.isLoadingGuests,"variant":"primary"},on:{"click":_vm.searchGuestByRoom}},[(_vm.isLoadingGuests)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1):_vm._e()],1)],1),(_vm.guests.length)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Huésped","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Huésped*"}},[_c('v-select',{class:errors[0] ? 'is-invalid' : valid ? 'is-valid' : null,attrs:{"options":_vm.guests,"label":"fullname","placeholder":"Seleccione Huésped"},on:{"input":function($event){return _vm.setCustomer(_vm.selectedGuest)}},model:{value:(_vm.selectedGuest),callback:function ($$v) {_vm.selectedGuest=$$v},expression:"selectedGuest"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,false,2820755276)})],1):_vm._e()],1),_c('hr'),_c('b-overlay',{attrs:{"show":_vm.isLoadingGuestInfo}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre*"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Nombre del cliente","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.guest.name),callback:function ($$v) {_vm.$set(_vm.guest, "name", $$v)},expression:"guest.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Apellido*"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Apellido del cliente","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.guest.lastname),callback:function ($$v) {_vm.$set(_vm.guest, "lastname", $$v)},expression:"guest.lastname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"idioma","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Idioma*"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.guest.language),callback:function ($$v) {_vm.$set(_vm.guest, "language", $$v)},expression:"guest.language"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione Idioma")]),_vm._l((_vm.languages),function(lang){return _c('option',{key:lang.id,domProps:{"value":lang.id}},[_vm._v(" "+_vm._s(lang.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:1","name":"número de pax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Número de pax*"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","step":"1","placeholder":"","state":errors[0] ? false : valid ? true : null},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.guest.pax),callback:function ($$v) {_vm.$set(_vm.guest, "pax", $$v)},expression:"guest.pax"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Código de pais"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Código de pais*"}},[_c('v-select',{class:errors[0] ? 'is-invalid' : valid ? 'is-valid' : null,attrs:{"label":"label","options":_vm.countryCodes,"filter":_vm.customFilterLada},model:{value:(_vm.guest.lada),callback:function ($$v) {_vm.$set(_vm.guest, "lada", $$v)},expression:"guest.lada"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"teléfono","rules":"","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Teléfono*"}},[_c('b-form-input',{class:errors[0] ? 'is-invalid' : valid ? 'is-valid' : null,attrs:{"oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"20","type":"text","autocomplete":"off","placeholder":"Ingrese número de teléfono"},on:{"keypress":_vm.formatPhoneNumber},model:{value:(_vm.guest.phone),callback:function ($$v) {_vm.$set(_vm.guest, "phone", $$v)},expression:"guest.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0] || (!!_vm.guest.phone && _vm.guest.phone.length < 10 ? 'El campo teléfono debe tener al menos 10 caracteres' : ''))+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Notas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Notas"}},[_c('b-form-textarea',{attrs:{"placeholder":"Inserte notas aquí (opcional)","state":errors[0] ? false : valid ? true : null,"rows":"4"},model:{value:(_vm.guest.notes),callback:function ($$v) {_vm.$set(_vm.guest, "notes", $$v)},expression:"guest.notes"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":_vm.invalid || _vm.isSavingCustomer}},[(_vm.isSavingCustomer)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Guardar ")],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }