<template>
  <b-modal
    size="lg"
    :id="'modal-add-reservation' + contractInfo.id"
    :title="`Añadiendo reservación al grupo ${contractInfo.name}`"
    ok-only
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :no-enforce-focus='true'
    :header-bg-variant="headerBgVariant"
    @show="resetData"
    >
    <b-card-text>

      <ValidationObserver ref="BookingGroupAddForm">
        <h3>Información de la Reservación</h3>

        <b-row>
          <b-col md="2">
            <ValidationProvider name="Prefijo" rules="required">
              <b-form-group label="Prefijo" slot-scope="{ valid, errors }">
                <b-form-select :state="errors[0] ? false : valid ? true : null" v-model="bookingInfo.nameprefix"
                  :disabled="invalid">
                  <option :value="null">Seleccione una opción</option>
                  <option v-for="(namesF, index) in namesPrefix" :key="index" :value="namesF.value">
                    {{ namesF.text }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider rules="required" name="Nombre">
              <b-form-group slot-scope="{ valid, errors }" label="Nombre">
                <b-form-input type="text" :disabled="invalid" v-model="bookingInfo.givenname"
                  :state="errors[0] ? false : valid ? true : null">
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Apellido">
              <b-form-group slot-scope="{ valid, errors }" label="Apellido">
                <b-form-input type="text" :disabled="invalid" v-model="bookingInfo.surname"
                  :state="errors[0] ? false : valid ? true : null">
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required|email" name="Correo">
              <b-form-group slot-scope="{ valid, errors }" label="Correo">
                <b-form-input type="text" :disabled="invalid" v-model="bookingInfo.email"
                  :state="errors[0] ? false : valid ? true : null">
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider rules="required" name="teléfono">
              <b-form-group slot-scope="{ valid, errors }" label="Teléfono">
                <b-form-input type="text" :disabled="invalid" @keypress="formatPhoneNumber" v-model="bookingInfo.phonenumber"
                  :state="errors[0] ? false : valid ? true : null">
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12"><hr></b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <ValidationProvider rules="required" name="Tipo de Cuarto">
              <b-form-group :disabled="invalid" slot-scope="{ valid, errors }" label="Tipo de Cuarto">
                <b-form-select
                  v-model="bookingInfo.roomtypecode"
                  :state="errors[0] ? false : valid ? true : null"
                  @change="getBookingAvailable(bookingInfo.roomtypecode)"
                  >
                  <!-- @change="validMaxPax(bookingInfo.roomtypecode)" -->
                  <option :value="null" disabled>Seleccione una opción</option>
                  <option v-for="roomsItems in roomsAvailables" :key="roomsItems.roomId" :value="roomsItems.roomCode">
                    {{ roomsItems.roomName }} | {{ roomsItems.available }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row v-if="bookingInfo.roomtypecode && !isLoadingBookingInfo">
          <b-col md="3">
            <b-form-group>
              <label><strong>Check in - Check out*</strong></label>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="configRangeDate"
                @input="setRangeDaysAndTotalNights(rangeDate)"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <ValidationProvider name="Noches" rules="required">
              <b-form-group slot-scope="{ isValidNights, errors }" label="Noches">
                <b-form-input type="number" :disabled="true" v-model="bookingInfo.nights"
                  :state="errors[0] ? false : isValidNights ? true : null">
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
            <label v-if="isValidNights" class="nightsValid">Noches invalidas</label>

          </b-col>

          <b-col md="3">
            <ValidationProvider name="Tarifa">
              <b-form-group slot-scope="{ isValidNights }" label="Tarifa">
                <b-form-input
                  type="number"
                  :disabled="true"
                  v-model="bookingInfo.rateInit"
                  @input="getNights(bookingInfo)"
                  :state="isValidNights"
                >
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider name="Total" rules="required|min_value:1">
              <b-form-group label="Total" slot-scope="{valid, errors}">
                <b-form-input type="number" :disabled="true" v-model="bookingInfo.total" :state="errors[0] ? false : (valid ? true : null)">
                </b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12" v-if="availability">
            <b-alert variant="danger" show>
              <div class="alert-body text-center">
                <span>
                  <strong>¡Sin Tarifas! </strong>No se encontro disponibilidad.
                </span>
              </div>
            </b-alert>
          </b-col>


          <b-col md="3">
            <b-form-group class="m-0">
              <validation-provider name="Adultos">
                <b-form-group slot-scope="{ errors }" label="Adultos*">
                  <b-form-select
                    :disabled="invalid"
                    v-model="bookingInfo.adults"
                    @input="setCanEdit()"
                    :state="errors.length > 0 ? false : null"
                  >
                    <!-- @input="setDataForm" -->
                    <option v-for="(adulto, index) in validPax.adults " :key="index" :value="adulto">
                      {{ adulto }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="validPax.canBookChildren">
            <b-form-group class="m-0">
              <validation-provider #default="{ errors }" name="Niños">
                <label for="children">Niños*</label>
                <b-form-select
                  :disabled="invalid"
                  v-model="bookingInfo.children"
                  :state="errors.length > 0 ? false : null"
                  @input="createArrayNinios(bookingInfo), setCanEdit()"
                >
                  <option value="0">0</option>
                  <option v-for="(children, index) in validPax.children " :key="index" :value="children">
                    {{ children }}
                  </option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="" class="buttonUpdate" align-self="center">
            <b-button
              style="font-size:13px;"
              class="float-right"
              :disabled="(isValidNights || ismaxPax) || isRoomFull || isUpdating || isSaving"
              variant="primary"
              @click="updateRates(bookingInfo)"
            >
              {{ isUpdating ? "Buscando" : "Buscar" }} <b-spinner small v-if="isUpdating" />
            </b-button>
          </b-col>

          <b-col md="12" v-if="ismaxPax"> <label class="nightsValid">Numero de pax maximo N° {{ validPax.pax }} </label></b-col>


          <b-col md="12" v-if="show == true">
            <h3 class="mb-2">Pagos Parciales</h3>
          </b-col>

          <b-col md="3" v-if="show == true">
            <b-form-group label="Noches">
              <b-form-select :disabled="invalid" v-model="bookingInfo.orderdetail.nightspay" @change="setPriceNights(bookingInfo)">
                <option value="0">Noches</option>
                <option v-for="(night, index) in bookingInfo.nights" :key="index" :value="night">
                  {{ night }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="showChildsAges && !isLoadingBookingInfo">
          <b-col md="12"><hr></b-col>
          <b-col md="3 mb-1" v-for="(child, index) in bookingInfo.booking.ninios" :key="index">
            <div>
              <label :for="'AgeChild' + index">Edad niño {{ index + 1 }}*</label>
              <b-input-group>
                <b-form-select :disabled="invalid" :id="'AgeChild' + index" v-model="bookingInfo.booking.ninios[index].edad"
                  @change="setAgeNinio(index, bookingInfo.booking.ninios[index].edad, bookingInfo), setCanEdit()">
                  <option v-for="(edad, index) in ageChildrenOptions" :key="index" :value="edad.value">
                    {{ edad.text }}
                  </option>
                </b-form-select>
                <b-input-group-append>
                  <b-button variant="danger" size="sm" @click="deleteAgeChild(index, bookingInfo)">
                    <b-icon icon="trash-fill"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>

        <div v-if="isLoadingBookingInfo" class="text-center">
          <b-spinner /> <br> Cargando habitación disponible
        </div>

        <b-row v-if="contractInfo.referenceList.length > 0">
          <b-col md="12">
            <hr>
            <b-form-group label="Referido">
              <b-form-select
                :disabled="invalid"
                v-model="bookingInfo.reference"
              >
                <option :value="null">Seleccione Referido</option>
                <option
                  v-for="(reference, index) in contractInfo.referenceList "
                  :key="index"
                  :value="reference.trim()"
                >
                  {{ reference.trim() }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="3" v-if="show == true">
            <ValidationProvider rules="min:0" name="Primer Pago">
              <b-form-group slot-scope="{ valid, errors }" label="Primer Pago ">
                <b-form-input type="number" :disabled="invalid" v-model="bookingInfo.orderdetail.firstpay"
                  :state="errors[0] ? false : valid ? true : null" placeholder="Pago parcial a pagar">
                </b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row v-if="bookingInfo.roomtypecode && bookingInfo.total > 0">
          <b-col md="12">
            <hr>
            <h4 class="listPayTitle">Primer pago</h4>
          </b-col>
          <b-col md="4">
            <ValidationProvider rules="required" name="Forma de pago">
              <b-form-group slot-scope="{valid, errors}" label="Forma de pago">
                <b-form-select
                    :state="errors[0] ? false : (valid ? true : null)"
                    v-model="bookingInfo.payment.paymentMethod"
                  >
                    <option :value="null" disabled>Elija método de pago</option>
                    <option v-for="pm in paymentMethodList" :key="pm.id" :value="pm.id">
                      {{ pm.name }}
                    </option>
                  </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider rules="required" name="Fecha de pago">
              <b-form-group slot-scope="{valid, errors}" label="Fecha de pago">
                <flat-pickr
                  :config="{maxDate: 'today'}"
                  v-model="bookingInfo.payment.paymentDate"
                  :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : 'is-valid'}`"
                  :state="errors[0] ? false : valid ? true : null"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider rules="required" name="Moneda">
              <b-form-group slot-scope="{valid, errors}" label="Moneda">
                <b-form-select
                  :state="errors[0] ? false : (valid ? true : null)"
                  v-model="bookingInfo.payment.currency"
                >
                  <option :value="null" disabled>Elija método de pago</option>
                  <option v-for="cur in currencies" :key="cur.currencyid" :value="cur.currencyid">
                    {{ cur.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider :rules="{ required: true, min_value: 1 }" name="Importe">
              <b-form-group slot-scope="{valid, errors}" label="Importe">
                <b-form-input type="number" :disabled="invalid"
                  v-model="bookingInfo.payment.amount"
                  :state="errors[0] ? false : valid ? true : null"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                <b-badge variant="success" class="mt-1" v-if="selectedCurrency && selectedCurrency.value != 1">USD {{ formatCurency(currencyAmount) }}</b-badge>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="8">
            <ValidationProvider name="Referencia">
              <b-form-group slot-scope="{valid, errors}" label="Referencia">
                <b-form-input
                  type="text"
                  v-model="bookingInfo.payment.reference"
                  :state="errors[0] ? false : valid ? true : null"
                ></b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

      <b-col md="12" class="p-0 mt-2">
        <div class="float-right">
          <b-button :disabled="invalid || isEdit || isValidNights || isSaving || isUpdating" variant="primary" @click="updateRoom(bookingInfo)">
            {{ isSaving ? "Guardando" : "Guardar" }} <b-spinner small v-if="isSaving" />
          </b-button>
        </div>
      </b-col>

    </ValidationObserver>
    </b-card-text>
  </b-modal>
</template>

<script>
import * as moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import { showAlertMessage, toCurrency } from "@/helpers/helpers"
import { adultOptions, childrenOptions, ageChildrenOptions, namesPrefix } from "@/data/data"
import { acl } from "@/modules/auth/mixins/acl"
import flatPickr from 'vue-flatpickr-component'
export default {
  mixins: [acl],
  props: {
    contractInfo: {
      type: Object,
      required: true,
    },
    getBookingInfo: {
      type: Function,
      required: true
    }
  },
  components: {
    flatPickr
  },
  destroy(){
    this.resetData()
  },
  data() {
    return {
      show: false,
      isSaving: false,
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
      namesPrefix,
      invalid: false,
      isValidNights: false,
      isEdit: false,
      isUpdating: false,
      headerBgVariant: 'disponible',
      rangeDate: null,
      setDatein: null,
      setDateout: null,
      nightsValid: null,
      availability: false,
      validPax: {},
      isRoomFull: false,
      ismaxPax: false,
      isLoadingBookingInfo: false,

      bookingInfo: {
        nameprefix: null,
        givenname: null,
        surname: null,
        email: null,
        phonenumber: null,
        roomtypecode: null,
        reference: null,
        payment: {
          paymentMethod: null,
          paymentDate: null,
          currency: null,
          amount: null,
          reference: null
        },
      }
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("groups", ["ninos", "numberChildren", "roomsTypes",]),
    ...mapState("shop", ["cart", "errors", "paymentFormat"]),
    ...mapState("start", ["currencies"]),

    roomsAvailables(){
      return this.contractInfo.rooms.data.filter(room=> room.available > 0).map(room => {
        return {
          roomId: room.roomId,
          roomCode: room.roomCode,
          roomName: room.roomName,
          available: room.available
        }
      })
    },

    paymentMethodList(){
      return [
        {id: 1, name: "Credit Card"},
        {id: 30, name: "Wire Transfer"}
      ]
      // return this.paymentMethods.filter(pm => pm.isownersPayment == false)
    },
    showChildsAges(){
      return !!this.bookingInfo?.roomtypecode && this.bookingInfo?.booking?.ninios?.length > 0
    },
    selectedCurrency(){
      return this.currencies.find(currency=> currency.currencyid == this.bookingInfo.payment.currency)
    },
    currencyAmount(){
      return this.bookingInfo?.payment?.amount && this.selectedCurrency?.value ? (this.bookingInfo?.payment?.amount / this.selectedCurrency?.value).toFixed(2) : 0.00
    },

    configRangeDate() {
      let config = {mode: 'range', static: true}

      if (!!this.bookingInfo.roomslist) {
        this.validPax = this.bookingInfo.roomslist.find(
          (item) => item.roomtypecode == this.bookingInfo.roomtypecode
        );
        this.availability = false
        this.invalid = false;
        this.isValidNights = false;
        this.isEdit = false;
        let datein = moment(this.bookingInfo.datein)
        let dateout = moment(this.bookingInfo.dateout)
        this.setDatein = this.contractInfo?.arrivaldate
        this.setDateout = this.contractInfo?.departuredate
        let numNigths = parseFloat(this.contractInfo?.additionalNights)

        this.rangeDate = `${this.bookingInfo.datein} to ${this.bookingInfo.dateout}`
        if (this.can('groups_menu_show_membership_flexible_dates')) {
          this.contractInfo.isFlexDate = this.can('groups_menu_show_membership_flexible_dates')
        }


        datein = moment(this.contractInfo?.arrivaldate).subtract(numNigths, 'days').format('YYYY-MM-DD');
        dateout = moment(this.contractInfo?.departuredate).add(numNigths, 'days').format('YYYY-MM-DD');

        config.minDate = datein
        config.maxDate = dateout
      }

      return config
    }

  },
  methods: {
    ...mapActions("groups", ["fetchDataOrderBooking", "fetchTransactionsBooking"]),
    ...mapActions("groups", ["fetchUpdateRoomsDetail", "fetchRoomBooking", "fetchRatesGroup"]),
    ...mapActions("mySales", ["fetchTransactionPay"]),
    ...mapMutations("groups", [
      "addArrayNinios",
      "setAgeChild",
      "removeAgeChild",
      "setTotalNights",
    ]),

    async getBookingAvailable(code){
      this.isLoadingBookingInfo = true
      const selectedRoom = this.roomsAvailables.find(room=> room.roomCode == code)
      if (!selectedRoom) return false

      const response = await this.getBookingInfo({
        idGroup: this.contractInfo.id,
        idRoom:selectedRoom?.roomId,
        idRoomOnly:"",
        getAvailable: 1
      })

      if (response) {
        try {
          const newBooking = {
            ...response[0],
            nameprefix: this.bookingInfo.nameprefix,
            givenname: this.bookingInfo.givenname,
            surname: this.bookingInfo.surname,
            email: this.bookingInfo.email,
            phonenumber: this.bookingInfo.phonenumber,
            payment: {
              paymentMethod: null,
              paymentDate: null,
              currency: null,
              amount: null,
              reference: null
            },
          }

          this.bookingInfo = newBooking
          this.rangeDate = `${this.bookingInfo.datein} to ${this.bookingInfo.dateout}`
          this.validMaxPax(code)
        } catch (errors) {
          console.warn(errors);
        }
        this.isLoadingBookingInfo = false
      } else {
        showAlertMessage("No se ha encontrado una habitación", "BellIcon", "No se ha podido obtener la información de la habitación solicitada, por favor inténtelo más tarde o contacte a soporte.", "warning", 4000)
      }

      this.isLoadingBookingInfo = false
    },

    ageChild(object) {
      return object.edad;
    },
    createArrayNinios(numberOfChildren) {
      let ageChildrens = [];
      for (let i = 0; i < numberOfChildren.children; i++) {
        ageChildrens.push({ edad: 1 });
      }
      const payload = { ageChildrens, info: numberOfChildren };
      this.addArrayNinios(payload);
    },
    setAgeNinio(indice, edad, room) {
      const payload = { indice, edad, room };
      this.setAgeChild(payload);
    },
    deleteAgeChild(indice, room) {
      const payload = { indice, info: room };

      this.removeAgeChild(payload);
    },
    getNights(room) {
      const datein = moment(this.contractInfo?.arrivaldate);
      const dateout = moment(this.contractInfo?.departuredate);
      this.bookingInfo.nights = dateout.diff(datein, "days");
      this.bookingInfo.total = room.rateInit * '0';
    },

    setRangeDaysAndTotalNights(rangeDateNew) {

      if (rangeDateNew.includes("to")) {
        const splitdate = rangeDateNew.split(' to ')

        const datein = moment(splitdate[0])
        const dateout = moment(splitdate[1])
        const dateinContract = moment(this.contractInfo?.arrivaldate);
        const dateoutContract = moment(this.contractInfo?.departuredate);

        this.bookingInfo.total = this.bookingInfo.rateInit * 0;
        this.bookingInfo.rateInit = 0
        this.nightsValid = dateout.diff(datein, "days")
        // let sameNight = this.calculateNights(dateout, dateinContract);

        // if (!this.contractInfo?.isFlexDate) {
        //   this.isEdit = true
        //   this.isValidNights = false

        //   const validDates = this.areDatesAfterContractDates(datein, dateout, dateinContract, dateoutContract);
        //   this.isValidNights = !validDates

        // } else {
        //   if (this.contractInfo?.nightsGroup > this.nightsValid) {
        //     this.isValidNights = true
        //   } else {
        //     this.isEdit = true
        //     this.isValidNights = false

        //     if (sameNight != 0 && this.contractInfo?.isFlexDate) {
        //       this.isValidNights = false
        //     } else {
        //       if (this.contractInfo?.isFlexDate) {
        //         this.isValidNights = true
        //       }
        //     }
        //   }
        // }
        this.bookingInfo.nights = dateout.diff(datein, "days");
        this.setDatein = splitdate[0]
        this.setDateout = splitdate[1]
      }
    },

    setCanEdit() {
      const paxTotal = parseFloat(this.bookingInfo.adults) + parseFloat(this.bookingInfo.children)
      this.ismaxPax = paxTotal > this.validPax.pax

      this.setRangeDaysAndTotalNights(this.rangeDate)
    },

    validMaxPax(roomCode) {
      this.validPax = this.bookingInfo.roomslist?.find(
        (item) => item.roomtypecode == roomCode
      );

      this.bookingInfo.adults = 1
      this.bookingInfo.children = 0
      this.setRangeDaysAndTotalNights(this.rangeDate)
      this.setCanEdit()
      this.createArrayNinios(this.bookingInfo)
      if (this.validPax.rooms == 0) {
        this.isRoomFull = true;
        this.isEdit = true;
      } else {
        this.isRoomFull = false;
        this.isEdit = false;
      }
    },

    areDatesAfterContractDates(datein, dateout, dateinContract, dateoutContract) {
      return datein <= dateinContract && dateout >= dateoutContract;
    },
    async updateRates() {
      const room = this.bookingInfo

      const splitdate = this.rangeDate.split(' to ')
      this.setDatein = splitdate[0]
      this.setDateout = splitdate[1]
      this.isUpdating = true;
      const ages = room?.booking?.ninios?.map(child => child.edad)

      const roomParameters = {
        "hotel": room.hotel,
        "groupId": room.group,
        "roomtypecode": room.roomtypecode,
        "dateIn": this.setDatein,
        "dateOut": this.setDateout,
        "adults": room.adults,
        "nights": room.nights,
        "ninos": ages,
        "isAdmin": true
      }

      const rates = await this.fetchRatesGroup(roomParameters);

      this.isUpdating = false;
      this.availability = rates.total == 0
      if (this.availability) return false

      this.bookingInfo.rateInit = rates.rateavg
      this.bookingInfo.total = rates.total
      this.isUpdating = false;
      this.isEdit = false
    },

    async setPriceNights(room) {
      this.setTotalNights(room);
    },
    async updateRoom(room) {
      const valid = await this.$refs.BookingGroupAddForm.validate()
      if (this.currencyAmount > room.total) {
        return showAlertMessage("Monto inválido", "BellIcon", "El importe por pagar es mayor al total de la reserva, por favor cambie la cantidad a pagar.", "warning", 4000)
      }
      if (valid) {
        room.datein = this.setDatein
        room.dateout = this.setDateout
        this.isSaving = true;
        let passRoom = room.room
        room.roomCategory = this.roomsTypes.find(
          (item) => item.code == room.roomtypecode
        );
        if (room.roomCategory) {
          room.roomtypename = room.roomCategory.name
          room.room = room.roomCategory.id
        }

        room.bookingid = room.id
        room.dateIn = room.datein
        room.dateOut = room.dateout
        room.vendor = room.vendorcode

        room.groupId = room.group
        room.roomId = room.room
        room.nightspay = room.orderdetail.nightspay
        room.partial = false
        room.paymentParcial = room.orderdetail.firstpay


        const ages = room?.booking?.ninios ? room.booking.ninios.map(ninio => ninio.edad) : []
        room.ninos = ages

        room.payment.idUser = this.user.idUser

        const response = await this.fetchUpdateRoomsDetail(room);
        this.isSaving = false;

        if (response) {
          this.$emit('change-to-edition-modal', {booking: room.id, group:this.contractInfo.id})
        } else {
          showAlertMessage("No se ha añadido la reserva", "BellIcon", "No se ha podido guardar el registro, por favor inténtelo más tarde o contacte a soporte.", "warning", 4000)
        }
      }

    },

    formatPhoneNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const respuesta = codesAllowed.includes(keyCode);
      if (!respuesta) event.preventDefault()
    },

    calculateNights(dateout, dateinContract) {
      return dateout.diff(dateinContract, "days");
    },
    resetData(){
      Object.assign(this.$data, this.$options.data())
      this.$refs.BookingGroupAddForm?.reset()
    },
    formatCurency(amount){
      return toCurrency(amount)
    }
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
table.listPay th {
  padding: 3px;
}


table.listPay td {
  padding: 10px 21px 10px 21px;
}

table.listPay {
  text-align: center;
  font-size: 11px;
}

.modal .modal-header.bg-pagado {
  background-color: #28a745;

}

.bg-disponible {
  background-color: #17a2b8;

}

.modal .modal-header.bg-pendiente {
  background-color: #ffc107;

}

.modal .modal-header.bg-pendiente h5 {
  color: #000;
}

.modal .modal-header.bg-pagado h5 {
  color: #fff;
}

.buttonUpdate.col-md-2 {
  margin: 6px 0 0 0;
}

label.nightsValid {
  TEXT-ALIGN: center;
  display: block;
  /* background: red; */
  /* color: #fff; */
  font-size: 11px;
  margin: -15px 0 0 0;
  background: red;
  color: #fff;
}
</style>
