<template>
  <b-modal size="lg" :id="'modal-inventory' + contractInfo.id" ok-only hide-footer class="status"
    :no-enforce-focus='true'>
    <b-card-text>

      <ValidationObserver ref="observerReference"  v-slot="{ invalid }">
        <h3>Información de la Reservación</h3>

        <b-row class="mb-2">
          <b-col md="6">
            <ValidationProvider name="Categoria">
              <b-form-group slot-scope="{ valid, errors }" label="Categoria">
                <b-form-select v-model="roomtypecode" :state="errors[0] ? false : valid ? true : null">
                  <option value="null">Seleccione</option>
                  <option v-for="roomsItems in room" :key="roomsItems.room__id" :value="roomsItems.room__code">
                    {{ roomsItems.room__code }} | {{ roomsItems.room__name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col md="3">
            <ValidationProvider rules="required" name="N° de Cuartos">
              <b-form-group slot-scope="{ valid, errors }" label="N° Cuartos">
                <b-form-input v-model="numRooms" @keypress="onlyNumber"
                  :state="errors[0] ? false : valid ? true : null"></b-form-input>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider rules="required" name="Ocupación">
              <b-form-group slot-scope="{ valid, errors }" label="Ocupación">
                <b-form-input v-model="occupation" @keypress="onlyNumber"
                  :state="errors[0] ? false : valid ? true : null"></b-form-input>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <ValidationProvider rules="required" name="Honey Money">
              <b-form-group slot-scope="{ valid, errors }" label="Honey Money">
                <b-form-input type="number" v-model="honeyMoney" @blur="formatAmount(honeyMoney)" step="0.01"
                  @keypress="onlyNumberWithDecimal" :state="errors[0] ? false : valid ? true : null"></b-form-input>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="3">
            <validation-provider name="adultos" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Max Adultos*">
                <b-form-select v-model="adultsNumber" :state="errors[0] ? false : valid ? true : null">
                  <option v-for="(adulto, index) in adultOptions" :key="index" :value="adulto.value"> {{ adulto.title }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="3">
            <validation-provider name="niños" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Max Niños*">
                <b-form-select v-model="numberChildren" :options="childrenOptions"
                  :state="errors[0] ? false : valid ? true : null"></b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="3">
            <ValidationProvider name="Permitir Niños">
              <b-form-group slot-scope="{ valid, errors }" :state="errors[0] ? false : valid ? true : null"
                label="Permitir Niños">
                <b-form-checkbox v-model="hasChildren" true-value="1" false-value="0"
                  class="text-center"></b-form-checkbox>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>


        </b-row>


        <b-col md="12">
    <div class="float-right">
      <b-button :disabled="invalid || isSaving" @click="setAllomentRoom(room)" variant="primary">
        <b-spinner small v-if="isSavingLoad" /> Guardar
      </b-button>
    </div>
  </b-col>

      </ValidationObserver>

    </b-card-text>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils";
import { adultOptions, childrenOptions } from "@/data/data"
import { toDecimal } from '@/helpers/helpers'
import flatPickr from 'vue-flatpickr-component'
export default {
  async created() {
  },
  mixins: [utils],
  props: {
    room: {
      type: Array,
      required: true,
    },

    contractInfo: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  components: {

    flatPickr
  },
  data() {
    return {

      roomtypecode: null,
      adultOptions,
      honeyMoney: 0,
      childrenOptions,
      occupation: 0,
      adultsNumber: "1",
      numberChildren: "0",
      numRooms: 1,
      hasChildren: 0

    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("groups", [
      "roomsTypes",
    ]),
    ...mapState("shop", ["cart", "errors", "paymentFormat"]),

    isValid() {
      if (this.room.length === 0) {
        return true
      }
      return false
    },
    isSavingLoad() {
      if (this.isSaving==false){
        this.roomtypecode = null,
        this.honeyMoney = 0,
        this.occupation = 0,
        this.adultsNumber = "1",
        this.numberChildren = "0",
        this.numRooms = 1,
        this.hasChildren = 0
      }
      
      return this.isSaving
    }
  },
  methods: {
    ...mapActions("groups", ["fetchDataOrderBooking", "fetchTransactionsBooking"]),
    ...mapActions("groups", ["fetchRoomBooking", "fetchRatesGroup"]),
    ...mapActions("mySales", ["fetchTransactionPay"]),

    formatAmount(amount) {
      if (amount) this.honeyMoney =toDecimal(amount)
    },
    async setAllomentRoom() {

      const newRoom = {
        roomCode: this.roomtypecode,
        honeyMoney: this.honeyMoney,
        occupation: this.occupation,
        adultsNumber: this.adultsNumber,
        numberChildren: this.numberChildren,
        numRooms: this.numRooms,
        hasChildren: this.hasChildren,
        available: 0,
        group: this.contractInfo.id
      };

      const payload = {
        room: newRoom,
        totalRooms: 0,
        note: "Nueva categoria",
        user: this.user.idUser,
        available: 0,
        isCreateCategory: true
      };

      await this.$emit("send-allotment", payload);
      
     
    }
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
