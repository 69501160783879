import Vue from 'vue'
import { showAlertMessage } from '@/helpers/helpers'
import { fetchHotels, fetchCurrencies } from '@/modules/start/store/actions'
import { fetchBenefitInfo } from '@/modules/fivesClub/store/catalogs/actions'

export async function getInitialContentForBenefitsMonetizationConfig(context, required) {
    if (required?.benefits) {
        const benefits = await fetchBenefitInfo(context, {title: true, monetizable: 1})
        context.commit('fivesClubCatalogs/setBenefitTitles', benefits, { root: true })
    }
    if (required?.hotels) {
        const hotels = await fetchHotels({showInWeb: true})
        context.commit('start/setHotels', hotels, { root: true })
    }
    if (required?.roomsTypes) {
        const roomsTypes = await fetchRoomSubtypes(context)
        context.commit('setRoomsSubTypes', roomsTypes)
    }
    if (required?.currencies) {
        const currencies = await fetchCurrencies()
        context.commit('start/setCurrencies', currencies, { root: true })
    }
}


export async function fetchGetRentalPoolFilter(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getRentalPoolFilter/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchGetRentalPoolFilter end')
    }
}
export async function fetchProfitGeneral(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getProfitGeneral/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchProfitGeneral end')
    }
}


export async function saveProfitClose(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getProfitGeneral/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return { status, message: response?.message, data }
    } catch (error) {
        const response = error?.response?.data
        if(payload.isAnual){
            showAlertMessage('Algo salió mal', 'BellIcon', `There was a problem ${response ? `: ${response?.message}` : ''}`, 'warning', 4000, 'bottom-right')
        }
        return { status: false, message: response?.message, data: null }
    } finally {
        console.log('La petición para bloquetar ha terminado')
    }
}



export async function calculateProfitCost(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/profit/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return { status, data }

    } catch (error) {
        console.log(error)
        const response = error?.response?.data
        showAlertMessage('Something went wrong', 'BellIcon', `There was a problem ${response ? `: ${response?.message}` : ''}`, 'warning', 4000, 'bottom-right')        
        return { status: false, message: response?.message, data: null }
    } finally {
        console.log('La petición para bloquetar ha terminado')
    }
}

export async function reCalculateProfitCost(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/profit/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return { status, message: response?.message, data }
    } catch (error) {        
        const response = error?.response?.data
       
        showAlertMessage('Something went wrong', 'BellIcon', `There was a problem making the contract${response ? `: ${response?.message}` : ''}, try again later.`, 'warning', 4000, 'bottom-right')        
        
        return { status: false, message: response?.message, data: null }
    
    } finally {
        console.log('La petición para bloquetar ha terminado')
    }
}


export async function fetchExcelProfitCost(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getProfitGeneral/`,
            responseType: 'blob',
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", `Profit_${payload?.filename}.xlsx`)
        document.body.appendChild(fileLink)
        fileLink.click()

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchExcelrofitCost end')
    }
}


export async function saveExpensesList(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getExpenses/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data,message } = respuesta
        if (status) showAlertMessage( "Ok: proceso finalizado",  "InfoIcon", message,"success", 4000,"bottom-right");
        return status
    } catch (error) {
        console.log(error)
        showAlertMessage('Something went wrong', 'BellIcon', 'There was a problem making the contract, try again later.', 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } finally {
        console.log('La petición para salvar los datos ha terminado')
    }
}



export async function fetchExpensesList(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getExpenses/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchExpensesList end')
    }
}

export async function fetchExpensesExcel(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getExpenses/`,
            responseType: 'blob',
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        if (respuesta?.type == "application/ms-excel"){
            const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
            const fileLink = document.createElement("a")
            fileLink.href = fileURL
            fileLink.setAttribute("download", 'reporte-ingresos-diarios.xlsx')
            document.body.appendChild(fileLink)
            fileLink.click()
            return true
        } else {
            showAlertMessage('No se han encontrado datos', 'BellIcon', "Esta busqueda no cuenta con datos, intentelo más tarde.", 'warning', 4000, 'bottom-right')
            return false
        }
    } catch (error) {
        console.log(error)
        showAlertMessage('Algo salió mal', 'BellIcon', 'Hubo un problema al solicitar el reporte, por favor inténtelo más tarde.', 'warning', 4000, 'bottom-right')
        return false
    } finally {
        console.log('fetchExpensesExcel end')
    }
}

export async function updatUxpensesList(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getExpenses/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data ,message} = respuesta
        if (status) showAlertMessage( "Ok: proceso finalizado",  "InfoIcon", message,"success", 4000,"bottom-right");
        return status ? data : []
    } catch (error) {
        console.log(error)
        showAlertMessage('Something went wrong', 'BellIcon', 'There was a problem making the contract, try again later.', 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } finally {
        console.log('Se a actualizado la informacion ha terminado')
    }
}

export async function fetchFeeSettings(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getFeeSettings/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchFeeSettings end')
    }
}

export async function fetchfeesType(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getFees/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchFeeSettings end')
    }
}

export async function saveFees(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getFeeSettings/`,
            data: payload
        })
        const respuesta = response && response.data
      
        return respuesta
    } catch (error) {
        console.log(error)
        showAlertMessage('Something went wrong', 'BellIcon', 'There was a problem making the contract, try again later.', 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } finally {
        console.log('La petición para salvar los datos ha terminado')
    }
}

export async function updateFees(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getFeeSettings/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        showAlertMessage('Something went wrong', 'BellIcon', 'There was a problem making the contract, try again later.', 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } finally {
        console.log('Se a actualizado la informacion ha terminado')
    }
}

export async function calculatePerformanceAnual(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getProfitAnualCalculation/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status
    } catch (error) {
        console.log(error)
        const respuesta = error?.response?.data
        showAlertMessage('Something went wrong', 'BellIcon', `There was a problem making the contract${respuesta ? `: ${respuesta?.message}` : ''}, try again later.`, 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } 
}

export async function recalculatePerformanceAnual(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getProfitAnualCalculation/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status
    } catch (error) {
        console.log(error)
        const respuesta = error?.response?.data
        showAlertMessage('Something went wrong', 'BellIcon', `There was a problem making the contract${respuesta ? `: ${respuesta?.message}` : ''}, try again later.`, 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } 
}


export async function uploadPerformanceAdjustment(context, payload) {
    const { file } = payload

    const formData = new FormData()
    formData.append('file', file)
    formData.append('data', JSON.stringify(payload))

    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/fivesClub/PerformanceAdjustmentsUpload/',
            data: formData
        })

        const respuesta = response && response.data
        return respuesta.status
    } catch (error) {
        //console.error('Error durante la carga de ajustes de rendimiento:', error)
        const respuesta = error?.response?.data
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al exportar los datos, inténtelo de nuevo más tarde.', 'warning', 4000, 'bottom-right')
        return false
    }
}


export async function savePerformanceAdjustment(context, payload) {

    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/fivesClub/getPerformanceAdjustments/',
            data: payload
        });

        if (response && response.status === 200) {
            showAlertMessage('Éxito', 'InfoIcon', 'Los datos se guardaron correctamente.', 'success', 4000, 'bottom-right');
            return response.data;
        }
    } catch (error) {
        //console.error('Error during performance adjustment save:', error);
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al realizar el ajuste de rendimiento, inténtelo de nuevo más tarde.', 'warning', 4000, 'bottom-right');
        return { status: false, data: [] };
    }
}

export async function updatePerformanceAdjust(context, payload) {

    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: '/fivesClub/getPerformanceAdjustments/',
            data: payload
        });

        if (response && response.status === 200) {
            showAlertMessage('Éxito', 'InfoIcon', 'Los datos se actualizaron correctamente.', 'success', 4000, 'bottom-right');
            return response.data;
        }
    } catch (error) {
        //console.error('Error during performance adjustment modification:', error);
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al realizar el ajuste de rendimiento, inténtelo de nuevo más tarde.', 'warning', 4000, 'bottom-right');
        return { status: false, data: [] };
    }
}

export async function fetchCatalogPerformanceConcept(context){
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getCatalogPerformanceConcepts/`
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        //console.error('fetchCatalogPerformanceConcept:', error);
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al obtener el catálogo de conceptos, inténtelo de nuevo más tarde.', 'warning', 4000, 'bottom-right');
        return [];
    }     
}


export async function fetchPerformanceConfigutation(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getPerformanceConcepts/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } 
}

export async function savePerformanceConfigutation(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getPerformanceConcepts/`,
            data: payload
        })

        if (response && response.status === 200) {
            showAlertMessage('Éxito', 'InfoIcon', 'Los datos se guardaron correctamente.', 'success', 4000, 'bottom-right');
            return response.data;
        }
    } catch (error) {
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al guardar la configuración del informe.', 'warning', 4000, 'bottom-right');
        return { status: false, data: [] }
    } 
}

export async function updatePerformanceConfigutation(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getPerformanceConcepts/`,
            data: payload
        })

        if (response && response.status === 200) {
            showAlertMessage('Éxito', 'InfoIcon', 'Los datos se actualizaron correctamente.', 'success', 4000, 'bottom-right');
            return response.data;
        }
    } catch (error) {
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al actualizar la configuración del informe.', 'warning', 4000, 'bottom-right');
        return { status: false, data: [] }
    } 
}


export async function fetchBenefitsMonetizationConfig(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getBenefitsMonetizationConfig/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    }
}

export async function saveBenefitsMonetizationConfig(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getBenefitsMonetizationConfig/`,
            data: payload
        })

        const respuesta = response && response.data
        if (respuesta?.status) {
            showAlertMessage('Éxito', 'InfoIcon', 'Los datos se guardaron correctamente.', 'success', 4000, 'bottom-right');
            return true
        } else return showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al guardar la configuración del informe.', 'warning', 4000, 'bottom-right')

    } catch (error) {
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al guardar la configuración del informe.', 'warning', 4000, 'bottom-right');
        return false
    } 
}

export async function updateBenefitsMonetizationConfig(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getBenefitsMonetizationConfig/`,
            data: payload
        })

        const respuesta = response && response.data
        if (respuesta.status) {
            showAlertMessage('Éxito', 'InfoIcon', 'Los datos se actualizaron correctamente.', 'success', 4000, 'bottom-right');
            return true;
        } else return showAlertMessage('Error', 'BellIcon', respuesta?.message || 'Hubo un problema al actualizar la configuración del informe.', 'warning', 4000, 'bottom-right');

    } catch (error) {
        const respuesta = error?.response?.data;
        showAlertMessage('Error', 'BellIcon',  respuesta?.message || 'Hubo un problema al actualizar la configuración del informe.', 'warning', 4000, 'bottom-right');
        return false
    }
}

export async function fetchRoomSubtypes(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/catalogs/getRoomSubtypes/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    }
}