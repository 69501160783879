// mixin.js
import { mapState } from 'vuex'
import { market } from '@/modules/shop/mixins/market'

export const login = {
  mixins: [market],
  computed: {
    ...mapState('auth', ['isLogged', 'permissions']),
  },
  methods: {
    redirectToDefault(redirect = true) {
      if (this.isLogged) {
        if (this.permissions.includes('marketplace_menu_show_marketplace')) {
          if (redirect) this.$router.push({ name: 'sales' })
          return "sales"
        }
        if (this.permissions.includes('bookings_menu_show_booking')) {
          if (redirect) this.$router.push({ name: 'booking' })
          return "booking"
        }
        if (this.permissions.includes('my_sales_menu_show_my_sales')) {
          if (redirect) this.$router.push({ name: 'my-sales' })
          return "my-sales"
        }
        if (this.permissions.includes('operation_menu_show_operation')) {
          if (redirect) this.$router.push({ name: 'operations' })
          return "operations"
        }
        if (this.permissions.includes('groups_menu_show_group')) {
          if (redirect) this.$router.push({ name: 'groups' })
          return "groups"
        }
        if (this.permissions.includes('faq_menu_show_faq')) {
          if (redirect) this.$router.push({ name: 'faq' })
          return "faq"
        }

        if (this.permissions.filter(menuFivesClub => menuFivesClub.toLowerCase().includes('fivesclub_sub_menu'))) {
          if (this.permissions.includes('fivesclub_owner_booking_show_booking')) {
            if (redirect) this.$router.push({ name: 'OwnersBooking', params: { index: 0 } })
            return "OwnersBooking"
          }
          if (this.permissions.includes('fivesclub_sub_menu_show_benefits')) {
            if (redirect) this.$router.push({ name: 'benefits' })
            return "benefits"
          }
          if (this.permissions.includes('fivesclub_sub_menu_show_blackouts')) {
            if (redirect) this.$router.push({ name: 'blackouts' })
            return "blackouts"
          }
          if (this.permissions.includes('fivesclub_sub_menu_show_housing')) {
            if (redirect) this.$router.push({ name: 'housing' })
            return "housing"
          }
          if (this.permissions.includes('fivesclub_owners_show_owners')) {
            if (redirect) this.$router.push({ name: 'customers' })
            return "customers"
          }
          if (this.permissions.includes('fivesclub_membership_show_membership')) {
            if (redirect) this.$router.push({ name: 'memberships' })
            return "memberships"
          }
          if (this.permissions.includes('fivesclub_sub_menu_show_contracts') || this.permissions.includes('fivesclub_contracts_show_contracts')) {
            if (redirect) this.$router.push({ name: 'contracts' })
            return "contracts"
          }
          if (this.permissions.includes('fivesclub_sub_menu_show_web_owners')) {
            if (redirect) this.$router.push({ name: 'owners-website-config' })
            return "owners-website"
          }
          if (this.permissions.includes('fivesclub_sub_menu_show_reports')) {
            if (redirect) this.$router.push({ name: 'Reports' })
            return "Reports"
          }
          if (this.permissions.includes('fivesclub_profit_sub_menu_show_profit')) {
            if (redirect) this.$router.push({ name: 'performanceConcepts' })
            return "performanceConcepts"
          }
        }

        if (this.permissions.includes('consumption_centers_menu_show_consumption_centers')) {
          if (redirect) this.$router.push({ name: 'waiting-list' })
          return "waiting-list"
        }
        if (redirect) this.logoutForce()
        else return false
      }
      return false
    },
  }
}